import { brandConstants } from "@/brand/current/PartnerConstants";
import { en } from "vuetify/src/locale";
import settingGroupManagement from "./setting-group-management";
import macros from "./macros";
import newChat from "./manualTicketCreation";
import ticketChatList from "./ticketChatList";
import shopify from "./shopify";
import autoAssign from "./autoAssign";
import liveChatPreChatFormConfig from "./liveChatPreChatFormConfig";
import email from "./email";

export default {
  ...en,
  $vuetify: {
    ...en,
    ...newChat.en,
    ...autoAssign.en,
    ...ticketChatList.en,
    ...shopify.en,
    ...settingGroupManagement.en,
    ...macros.en,
    ...liveChatPreChatFormConfig.en,
    ...email.en,
    loginPageSubmitAndRegisterSeparatorOR: "Or",
    loginAgreement: "By login in, you agree to {0}'s {1}",
    agreementName: "terms & conditions",
    help: "Help",
    connectAgent: "Connect with agent",
    manualGuide: "Open manual guide",

    labelSelect: "Select",
    labelSaldo: "Saldo",
    labelDownload: "Download",
    labelEmail: "Email",
    labelPassword: "Password",
    labelOptional: "Optional",
    labelLater: "Later",
    labelTopUpNow: "Top up now",
    labelUnderstand: "Understand",
    warnEmailRequired: "Email is required",
    warnEmailFormat: "Email format is invalid",
    warnPasswordRequired: "Password is required",
    noMutationDataText: "There is no mutation history",
    labelTopUpAlertSucceed:
      "Yeay! your top up is succeed with amount of {amount} Chat Credits.",
    labelDeleteFilter: "Remove filter",

    labelTransactionCloseLabel: "Yes, close",
    labelTransactionCancelCloseLabel: "Nope, back to transaction",
    labelTransactionTitleLabel: "Close & cancel transaction",
    labelTransactionDescLabel:
      "When this page closed, product you choosed will not saved",

    tabTitleBroadcastSend: "Send Broadcast",
    tabTitleBroadcastHistory: "History",
    tabTitleChatCreditsMutation: "Mutation",

    tabTitleChatSettingOperationalHour: "CS Operating Hours",
    tabTitleChatSettingFollowUp: "Follow Up",
    tabTitleChatSettingAutoClose: "Auto Close",
    tabTitleChatSettingQuickReplies: "Quick Replies",
    tabTitleChatSettingQuickRemarks: "Remarks",
    tabTitleChatSettingCategory: "Chat Tagging",
    tabTitleChatSettingSurveyRating: "Survey Rating",

    quickRepliesListTitle: "Quick replies list",
    quickRemarksListTitle: "Note list",
    quickRepliesListSubTitle:
      "CS agent can use quick replies to reply a chat instantly without having to type manually.",
    quickRepliesListAddButton: "Add a quick reply",
    quickRemarksListAddButton: "Add",
    quickRepliesDeleteDialogTitle: "Delete this quick reply?",
    quickRemarksDeleteDialogTitle: "Delete this note?",
    quickRepliesDeleteDialogDescription:
      "You will remove `{0}` from quick chat list.",
    quickRepliesDeleteSomeDialogTitle: "Delete {0} quick reply?",
    quickRepliesDeleteSomeDialogDescription:
      "You will remove {0} from quick chat list.",
    quickRepliesDeleteAllDialogTitle: "Delete all quick reply?",
    quickRepliesDeleteAllDialogDescription:
      "You will remove all from quick chat list.",
    quickRepliesEmptyTitle: "No quick reply yet",
    quickRepliesEmptyContent:
      "All the quick replies you added will appear here.",
    quickRemarksEmptyContent:
      "Yuk tambah keterangan sekarang, biar CS bisa memberikan judul topik untuk chat yang masuk",
    quickRepliesDupliationErrorMsg:
      "Judul quick reply sudah terdaftar sebelumnya",
    quickRemarksInfo:
      "Notes help CS agents to mark chat topics they want to close or resolve.",
    quickRemarksSuccessAddMsg: "Successfully add remark.",
    quickRepliesSuccessAddMsg: "Successfully add quick reply.",

    quickRemarksListRemarksName: "Note title",
    quickRemarksListRemarksDescription: "Description",

    quickRepliesSelectedCount: "{0} quick replies selected",
    quickRemarksSelectedCount: "{0} Notes selected",

    quickRemarksDeleteDialogDescription:
      "You will delete `{0}` from the note list",
    quickRemarksDeleteSomeDialogTitle: "Delete {0} note from the note list?",
    quickRemarksDeleteSomeDialogDescription:
      "You will delete {0} note from the note list.",
    quickRemarksDeleteAllDialogTitle: "Hapus semua keterangan?",
    quickRemarksDeleteAllDialogDescription:
      "Kamu akan menghapus semua yang ada di daftar keterangan",
    quickRemarksEmptyTitle: "Belum ada keterangan",
    quickRemarksDupliationErrorMsg:
      "The same note title is already on the list",

    quickRepliesAddTitle: "Add a quick reply",
    quickRemarksAddTitle: "Add note",
    quickRepliesEditTitle: "Edit Quick Replies",
    quickRemarksEditTitle: "Edit keterangan",
    quickRepliesNameTitle: "Title of quick reply",
    quickRepliesContentTitle: "Message",
    quickRemarksNameTitle: "Note title",
    quickRemarksContentTitle: "Description",
    quickRemarksNameSubTitle: "Example: Refund, Shipping, Rewards, etc",
    quickRemarksContentSubTitle: "Brief description based on note title",
    quickRepliesNameHint: "Enter the title here",
    quickRepliesContentHint: "Enter the message here",
    quickRepliesAddFieldError: "Exceed character limit.",
    quickRepliesAddButton: "Add",
    quickRepliesEditButton: "Save",

    quickRepliesSearchHint: "Search title of quick replies",
    quickRemarksSearchHint: "Search note title",

    quickRemarksPopupTitle: "Change status to Closed",

    quickRemarksChooseRemarksTitle: "Select note",
    quickRemarksContentRemarksTitle: "Description",
    quickRemarksContentRemarksTitleDetail:
      "Brief description based on note title",

    chatCategoryInfo:
      "Chat tagging help CS agents to mark ongoing chat topics.",
    chatCategoryListTitle: "Tag list",
    chatCategoryAddTitle: "Add tag",
    chattagEditTitle: "Edit tag",
    chatCategoryNameTitle: "Chat tagging name",
    chatCategoryContentTitle: "Isi Keterangan",
    chatCategoryNameSubTitle:
      "Example: New customer, complaint, follow up, paid, etc",
    chatCategorySearchHint: "Search chat tagging name",
    chatCategoryDeleteDialogTitle: "Delete this chat tagging?",
    chatCategoryDeleteDialogDescription:
      "You will delete ‘{0}’ from the chat tagging list",
    chatCategoryEmptyTitle: "Belum ada kategori chat",
    chatCategoryEmptyContent:
      "Yuk tambah kategori chat sekarang, biar CS bisa memberikan topik untuk chat yang masuk",
    chatCategoryDuplicateErrorMsg:
      "Nama kategori chat sudah terdaftar sebelumnya",
    chatCategorySuccessAddMsg: "Berhasil menambah kategori chat.",
    chatCategoryAddPlaceholder: "Add chat tagging",
    chatCategoryPickPlaceholder: "Tags",
    chatCategoryListName: "Tag name",

    settingPlanAndPricingTitleLabel: "Plan & Pricing",
    settingPlanAndPricingDescriptionLabel:
      "Choose KOALA+ package based on your store needs",

    planAndPricingPlanTitle: "Choose a plan that works for you",
    planAndPricingPlanDescription:
      "There is a bonus of up to a total of 350,000 Chat Credits every month to enjoy all the premium features of KOALA+.",
    planAndPricingPlanTaxDescription: "Price is subject to 11% VAT",
    planAndPricingPlanButtonLabel: "Berlangganan",
    planAndPricingPlanButtonLabelExtend: "Perpanjang",
    planAndPricingPlanButtonCurrentLabel: "Paket saat ini",
    planAndPricingPlanNote:
      "*Promo berlaku bagi yang berlangganan sebelum 31 Desember 2022",
    planAndPricingWABAAbout: "What is WhatsApp Business API?",
    planAndPricingWABAAboutDesc:
      "WhatsApp Business API (WABA) enables businesses to automate communication with auto-replies, WhatsApp chatbots, and interactive messages. To use {0}, you need to connect your WABA account in {0}.",
    planAndPricingWABARegister: "How to register?",
    planAndPricingWABARegisterStep1: "Create Facebook Business Manager account",
    planAndPricingWABARegisterStep2:
      "Verify and connect the account with WhatsApp Business API",
    planAndPricingWABARegisterStep3: "Register and subscribe {0} premium",
    planAndPricingWABAImportantNote1:
      "Please use a dedicated number to create WhatsApp Business API account",
    planAndPricingWABAImportantNote1Desc:
      "You can’t use the mobile phone number in WhatsApp app or web after connected to WhatsApp Business API",
    planAndPricingWABAImportantNote2: "Prepare these required information:",
    planAndPricingWABAImportantNote2List1:
      "Display Name of your business that can be seen by customers chatting with you.",
    planAndPricingWABAImportantNote2List2:
      "Details of your business (product/service description, address, email).",
    planAndPricingWABAImportantNote2List3: "Your store website",
    planAndPricingWABAImportantNote2List4:
      "Legal documents of your business (NPWP/tax identification number, company legality, etc.).",
    planAndPricingWABAAccountCriteria:
      "Make sure your account meets the following criterias:",
    planAndPricingWABAAccountCriteriaList1:
      "The account to connect WABA at least needs to be given the partial access role in the Facebook Business Manager account",
    planAndPricingWABAAccountCriteriaList2:
      "The account does not have the {0} in the Facebook Business Manager account",
    planAndPricingWABAAccountLimitation: "feature limitation",

    planAndPricingWABAInfo: "Important notes:",
    planAndPricingWABANextButton: "Next",
    planAndPricingWABATitle: "Hubungkan akun WABA",
    planAndPricingWABADescription:
      "Untuk mulai berlangganan KOALA+, kamu harus menghubungkan akun WhatsApp Business API (WABA) dengan KOALA+ dulu.",
    planAndPricingWABAAlert: "Connect your WhatsApp Business API account",
    planAndPricingWABAAlertDescription:
      "For subscription, please connect WhatsApp Business API account with KOALA+ first",
    planAndPricingWABAConnectNow: "Connect now",
    planAndPricingWABAButtonLabel: "Mulai sekarang",
    planAndPricingWABAPackageVariant: "Package Variant",
    planAndPricingWABAPricingInfo: "Pricing Information",
    planAndPricingWABAFeeTitle: "WABA Pricing",
    planAndPricingWABAFeeComponentTitle:
      "Outbound Message fee changes: Effective June 1, 2023",
    planAndPricingWABAFeeDescription:
      "Applied broadcast message cost will be different based on template category and adjustments on bonus credit usage regulation.",
    planAndPricingWABAFeeDescription2:
      "WhatsApp Business API has separated fee from {0} subscription.",
    planAndPricingWABAFeeDescription3:
      "You can top up Chat Credits here to use WABA.",
    planAndPricingWABAFeeButtonLabel: "Top Up Chat Credits",
    planAndPricingAlert:
      "The subscription fee has not been paid. Please make a payment of Rp {0} now or please refresh the page if you have paid.",

    planAndPricingWABAOutboundFeeCardTitle: "Outbound Message Fee",
    planAndPricingWABAOutboundFeeCardSubtitle:
      "Broadcast message fee to customer according to message category.",
    planAndPricingWABABonusCreditChangesTitle: "Bonus T&C Changes",
    planAndPricingWABABonusCreditChangesDetailName: "Changes Detail",
    planAndPricingWABABonusCreditCardTitle: "Bonus Chat Credits",
    planAndPricingWABABonusCreditCardSubtitle:
      "Bonus Chat Credits will settled to bonus inbound balance and only for inbound message usage.",
    planAndPricingWABAFeeCardTitle: "Inbound Message Fee",
    planAndPricingWABAFeeCardSubtitle:
      "Chat fee from customer through chatbot or CS after replied by store in 24 hour.",
    planAndPricingWABAFeeCardPricing: "{0} Chat Credits",
    planAndPricingWABAFeeCardPerChat: "/chat",
    planAndPricingWABAFeeCardPpn: "*fee includes PPN 11%",
    planAndPricingWABAOutboundChangesTitle: "Changes fee outbound message",
    planAndPricingWABAOutboundChangesCategoryName: "Category",
    planAndPricingWABAOutboundChangesStart: "Before 1 June 2023",
    planAndPricingWABAOutboundChangesEnd: "Effective 1 June 2023",
    planAndPricingWABAOutboundChangesUnit: "Chat Credits/contact",
    planAndPricingWABAFeeBonusCreditTableAmountBonus: "Bonus amount",
    planAndPricingWABAFeeBonusCreditTableBonusMax: "Until {0}",
    planAndPricingWABAFeeBonusCreditTableUsage: "Usage",
    planAndPricingWABAFeeBonusCreditTableUsageInboundOutbound:
      "Inbound & outbound message usage",
    planAndPricingWABAFeeBonusCreditTableUsageInboundOnly: "Inbound usage only",
    planAndPricingWABAFeeBonusCreditTableDateObtained: "Date obtained",
    planAndPricingWABAFeeBonusCreditTableDateObtainedBefore: "Date registered",
    planAndPricingWABAFeeBonusCreditTableDateObtainedAfter: "1st every month",

    tabTitleUnassigned: "Unassigned",
    tabTitleAssigned: "Assigned",
    searchContactPlaceholder: "Search Contact",
    titleBlankChatroom: "No active chat yet",
    subtitleBlankChatroom: "Later you can find chats from customers here",
    noChat: "No incoming chat",
    noCategory: "No Tag",
    noTags: "No Tags",
    chatroomFilterShowAllTags: "Lihat semua",
    chatroomFilterHideTags: "Sembunyikan",
    latest: "Latest",
    oldest: "Oldest",
    apply: "Apply",
    selectedContact: "selected contact",
    forward: "Reassign",
    resolve: "Resolve",
    assign: "Assign",
    priority: "Priority",
    priorityNormalLabel: "Normal",
    prioritySevereLabel: "Medium",
    priorityCriticalLabel: "High",
    chatroomFilterSort: "Sort",
    chatroomFilterCategory: "Tag",
    chatroomFilterPriorityStatus: "Priority",
    chatroomFilterMultipleStore: "All Channel",
    csCreateTransaction: "Create Transaction",
    csCreateTransactionOrderDetail: "Transaction detail",
    csCreateTransactionOrderInput: "Input transaction data",
    csCreateTransactionOrderInputName: "Customer name",
    csCreateTransactionOrderInputPhone: "Phone",
    csCreateTransactionOrderInputDeliveryMethod: "Delivery method",
    csCreateTransactionOrderInputDeliveryCourierMethod: "Send with courier",
    csCreateTransactionOrderInputBuyerPickupMethod: "Customer visit store",
    csCreateTransactionSearchInCatalog: "Search product in catalog",
    csCreateTransactionOrderManualAction: "Not found? fill manually",
    csCreateTransactionOrderHere: "here",
    csCreateTransactionOrderNote: "Note (optional)",
    csCreateTransactionOrderAddressEmpty:
      "Ask Admin to fill in store address first",
    csCreateTransactionOrderDeliveryFeeDiscount: "Delivery fee discount!",
    csCreateTransactionOrderDeliveryFeeDiscountAmount:
      "Delivery fee discount until {0} for customer, with KOALA Partner Courier.",
    csCreateTransactionProductListEmptyTitle: "No product in the Catalog",
    csCreateTransactionProductListEmptyDesc:
      "Add product to catalog or add product manually",
    failedCreateTrxLabel:
      "Failed create transaction. Please try again in a few minutes.",
    sharePaymentLink: "Share payment link",
    titleForward: "Assign to CS",
    subtitleForward: "Select CS",
    description: "Description",
    caption1Forward: "Selected Contact to be resolved.",
    caption2Forward: "Chat with “Closed” status will move to Chat History menu",
    chatroomUnassignedInstruction:
      "Reply within 24 hours. You can assign the conversation to an agent who is online.",
    chatroomAssignedHasNotExpiryDateInstruction:
      "Chat will stay active unless the admin marked it as resolved.",
    chatroomReplyDeadline: "Remaining time to reply:",
    timeHourLabel: " Hours",
    enableNotificationForNewChatTitle: "Get notified on new messages",
    enableDesktopNotificationLink: "Turn on desktop notifications",
    showContactDetailLabel: "Show contact detail",
    searchTicketIDPlaceholder: "Search {0}",

    chatroomFilterApply: "Apply",
    chatReassign: "Reassign",
    chatCreatedAt: "Chat started",
    chatLastReply: "Last replied",
    chatExpired: "Expired",
    chatSession: "Session",
    chatroomSearchCS: "Search agent name",
    chatTicketID: "Ticket ID",

    commonDateTimeYesterday: "Yesterday",
    commonDateTimeEveryDay: "Every Day",
    commonDateTimeToday: "Today",
    commonDateTimeMonday: "Monday",
    commonDateTimeTuesday: "Tuesday",
    commonDateTimeWednesday: "Wednesday",
    commonDateTimeThursday: "Thursday",
    commonDateTimeFriday: "Friday",
    commonDateTimeSaturday: "Saturday",
    commonDateTimeSunday: "Sunday",

    chatroomCreateTransaction: "Create transaction",
    chatroomPhotoAndVideo: "Images and video",
    chatroomPhoto: "Images",
    chatroomDocument: "Document",
    chatroomAudio: "Audio",
    chatroomChatInputPlaceholder: "Type your message here",
    chatroomExpiredAt: "Chat will be expired at ",
    chatroomHasExpired: "Chat has been expired",
    chatroomOpenUrlInGoogleMap: "Open in Google Map",
    chatRoomEmailAttachmentsSizeLimit: "File attached: {0}/25MB",
    chatRoomEmailAttachmentsSizeExceedsLimit:
      "Attached file exceeds limit: {0}/25MB",
    chatRoomSendAsInternalNote: "Send as internal note",
    chatRoomSendAsMessage: "Send as message",

    chatHistoryTitle: "Chat History",
    chatHistoryDisplay: "Open chat history",
    chatHistorySubtitle:
      "You can check the history of resolved and expired chat.",
    chatHistorySectionTitle: "History List",
    chatHistoryPlaceholderFilterPhone: "Find By Contact",
    chatHistoryPlaceholderFilterChannel: "Find By Channel",
    chatHistoryPlaceholderFilterDate: "Find By Date",
    chatHistoryColumnNameAndContact: "Nama & Contact Number",
    chatHistoryColumnContact: "Contact number",
    chatHistoryColumnChannel: "Channel",
    chatHistoryColumnCs: "CS Agent",
    chatHistoryColumnClosedBy: "Closed By",
    chatHistoryColumnClosedAt: "Resolved Time",
    chatHistoryColumnRemark: "Remarks",
    chatHistoryColumnAction: "Chat History",
    chatHistoryDialogTitle: "Chat History",
    chatHistoryOverlayText:
      "You can use this feature with subscribtion to support your sales.",
    chatHistoryOverlayButton: "Subscribe Now",
    chatHistoryEmptyTitle: "No history chat yet",
    callHistoryEmptyTitle: "No history call yet",
    chatHistoryEmptySubtitle1:
      "Chat history will appear here if there is any resolved or expired chat.",
    callHistoryEmptySubtitle1: "Call history will appear here.",
    chatHistoryColumnTicketID: "Ticket ID",
    chatHistoryColumnCategory: "Tag",
    chatHistorySearchName: "Name",
    chatHistorySearchAllChannel: "All channel",
    chatHistorySearchAssignedCS: "All CS (Handled By)",
    chatHistorySearchClosedByCS: "All CS (Closed By)",
    chatHistorySearchRemarks: "Search {0}",
    chatHistoryColumnTag: "Tag",
    chatHistoryEmailFilter: "Email",
    chatHistoryColumnRemarksOrSubject: "Remarks/Subject",

    noMutationTitle: "Empty Mutation",
    noMutationDesc: "There is no mutation on given period",
    noMutationClose: "Understand",

    topUpValidateTitle: "Failed to Top Up",
    topUpValidateDesc:
      "Please complete payment for top up process before this one.",
    topUpValidateClose: "Understand",

    broadcastPriceChanges:
      "Broadcast price will be charged effectively from June 1st, 2023",
    broadcastPriceChangesDescription:
      "Price will be different based on template message category.",
    broadcastPriceChangesSeeMore: "See detail",

    broadcastPriceAlert:
      "The fee for broadcast is {0} chat credits/contacts (11% VAT is included).",
    broadcastAddReceiverTitle: "Broadcast Receiver",
    broadcastAddReceiverSubtitle:
      "Enter recipient's phone number broadcast with number only.",
    broadcastAddReceiverPhoneHint: "Fill in mobile number",
    broadcastAddReceiverAddBtn: "Add",
    broadcastAddReceiverValidationInvalidNumber: "Invalid number",
    broadcastAddReceiverValidationInvalidLength: "Number must 10-13 digit",
    broadcastAddReceiverValidationInvalidAlreadyExit: "Number already exist",
    settingStoreStatusChannelNotFound:
      "Failed to connect account. Check your username or store link again to connect. {0}",
    settingStoreStatusChannelTryAgain: "Try again",
    settingStoreStatusChannelNotPowerMerchant:
      "Failed to connect account. Make sure your account status is Power Merchant/Power Merchant Pro/Official Store. {0}",
    broadcastCountryCodeTextFieldPlaceholder: "Country code",
    broadcastPhoneNumberTextFieldPlaceholder: "Recipient's phone number",

    broadcastBulkReceiverTitle: "Bulk Broadcast Recipient",
    broadcastBulkReceiverSubtitle:
      "Make sure the <b>.xls</b>/<b>.xslx</b> file format and file names are not changed.",
    broadcastBulkReceiverDownload: "Download Excel",
    broadcastBulkReceiverUpload: "Upload Excel",
    broadcastBulkReceiverValidationInvalidFormat: "Invalid file format",
    broadcastBulkReceiverValidationErrorUpload:
      "Upload failed, please check your internet network again",
    broadcastBulkReceiverValidationInvalidErrorMessage:
      "You have {0} invalid data.",
    broadcastBulkReceiverValidationInvalidDownloadFileContent: "Download file",
    broadcastBulkReceiverValidationInvalidContentErrorMessage:
      "for updating data.",
    noUploadedFileErrorMessage: "Please upload file to continue",
    broadcastAllContactTitle: "All Customers ({0} contacts)",
    broadcastAllContactSubTitle:
      "All customer contacts will be included in the broadcast list",
    broadcastAllContactNoData: "You don't have customer data yet ",
    broadcastAllContactAddContact: "Manage Contact",

    labelFilterSortBroadcastHistory: "Sort by",
    labelFilterTemplateBroadcastHistory: "Template Name",
    labelDateTemplateBroadcastHistory: "Date",
    columnNameTemplateBroadcastHistory: "Template Name",
    columnNameSentBroadcastHistory: "Total Recipients",
    columnNameCategoryBroadcastHistory: "Category & Subcategory",
    columnNameFailedBroadcastHistory: "Failed",
    columnNameBroadcastTimeBroadcastHistory: "Broadcast Time",
    columnNameDescriptionBroadcastHistory: "Note",
    columnNameDownloadCustomerDataBroadcastHistory: "Download Detail",
    columnNameStatusBroadcastHistory: "Broadcast Status",
    columnNameStatusDeliveredBroadcastHistory: "Delivered",
    columnNameStatusReadBroadcastHistory: "Read",
    columnNameStatusFailedBroadcastHistory: "Failed",
    columnNameStatusFailedMaxQuotaBroadcastHistory:
      "Failed. The daily quota has reached the maximum limit",
    columnNameStatusScheduledAtBroadcastHistory: "Scheduled at ",
    columnNameStatusSendingBroadcastHistory: "Sending",

    columnNameChatCreditsTime: "Time",
    columnNameChatCreditsMutationType: "Transaction Description",
    columnNameChatCreditsTransactionStatus: "Transaction Status",
    columnNameChatCreditsMutationAmount: "Mutation",
    columnNameChatCreditsMutationBalance: "Chat Credit Balance",

    columnNameChatSettingDayLabel: "Day",
    columnNameChatSettingOperationalHourLabel: "Operating hours",
    columnNameChatSettingMessageOnOperationalHourLabel:
      "Chat during operating hours",
    columnNameChatSettingMessageOutsideOperationalHourLabel:
      "Chat outside operating hours",
    columnNameChatSettingActionLabel: "Action",

    labelChatCreditsTopUpNominal: "Fill nominal Top Up",
    labelChatCreditsTopUpPaymentMethod: "Metode Pembayaran",
    labelChatCreditsTopUpSelectPaymentMethod: "Select payment method",
    labelChatCreditsTopUpContinuePayment: "Continue to payment",

    labelTitleChatSetting: "Chat based on operating hours",
    labelSubtitleChatSetting: "Operating hours in WIB",

    labelChatSettingAutoCloseTitle: "Chat auto close",
    labelChatSettingSurveyRatingTitle: "Variable Survey",
    labelChatSettingAutoCloseColumnTitle1: "Chat message",
    labelChatSettingAutoCloseColumnDescription1:
      "Auto close chat is an automated chat to close the conversation; when the customer doesn’t reply to CS agent.",
    labelChatSettingAutoCloseChatMessagePlaceholder: "Chat message",
    labelChatSettingAutoCloseChatMessageError: "Exceed character limit.",
    labelChatSettingAutoCloseChatDescriptionLabel: "Description",
    labelChatSettingAutoCloseChatDescriptionPlaceholder: "Description..",
    labelChatSettingAutoCloseColumnTitle2: "Send auto close chat after",
    labelChatSettingAutoCloseColumnTitle2Placeholder: "Enter number of minutes",
    labelChatSettingAutoCloseSuccessMessage:
      "Berhasil melakukan update chat auto close",
    labelChatSettingSurveyRatingSuccessMessage:
      "Berhasil melakukan update chat survey rating",
    labelChatSettingFollowUpTitle: "Follow up chat",
    labelChatSettingFollowUpColumnTitle1: "Follow up chat message",
    labelChatSettingFollowUpColumnDescription1:
      "Follow up chat is an automated chat sent to your customers who haven’t replied to chat from the CS agent.",
    labelChatSettingFollowUpColumnTitle2: "Send follow up chat after",
    labelChatSettingFollowUpColumnPlaceholder: "Enter number of minutes",
    labelChatSettingFollowUpMinute: "minute",
    labelChatSettingFollowUpSuccessMessage:
      "Successfully update follow up chat.",
    labelChatSettingFollowUpFailedMessage: "Failed to update chat follow up",
    labelChatSettingFollowUpSave: "Save",

    labelChatSettingCancelLabel: "Cancel",
    labelChatSettingResetLabel: "Yes, reset",
    labelChatSettingDialogTitleLabel: "Reset automatic",
    labelChatSettingDialogDescriptionLabel:
      "You will reset automatic chat and operating hours on Monday.",

    labelChatSettingOperationalHourEditDialogLabel: "Add",

    labelChatSettingOperationalHourEditDialogTitleLabel: "Change Auto Reply",
    labelChatSettingOperationalHourAddDialogTitleLabel: "Add Auto Reply",
    labelChatSettingOperationalHourEditDialogOperationalHourLabel:
      "Operating hours",
    labelChatSettingOperationalHourEditDialogSelectHourLabel:
      "Select hour (in WIB)",
    labelChatSettingOperationalHourEditDialog24HourLabel: "24 Jam",
    labelChatSettingOperationalHourEditDialogOpeningHourLabel: "Open at",
    labelChatSettingOperationalHourEditDialogCloseHourLabel: "Close at",
    labelChatSettingOperationalHourEditDialogChatInHourLabel:
      "Chat during operating hours",
    labelChatSettingOperationalHourEditDialogShowChatLabel:
      "Show this chat only once a day.",
    labelChatSettingOperationalHourEditDialogChatOutHourLabel:
      "Chat outside operating hours",
    labelChatSettingOperationalHourEditDialogApplyInAllDayLabel:
      "Apply every day?",

    appBarChatCredits: "Chat Credits",
    appBarCredits: "Credits",
    appBarContactCSParameterized: "Contact {0}",
    appBarManualGuide: "Manual guide",
    menuHome: "Dashboard",
    menuProduct: "Produk",
    whatsappCatalogue: "WhatsApp Katalog",
    listMenuCatalog: brandConstants.PARTNER_NAME + " Catalogue",
    listMenuCatalogWhatsapp: "Whatsapp Catalogue",
    menuTransaction: "Transaction",
    menuWhatapp: "Whatsapp",
    menuShopify: "Shopify",
    menuViber: "Viber",
    menuBroadcast: "Broadcast",
    menuTemplate: "Template",
    menuChatbot: "Chatbot",
    menuCustomerService: "Customer Service",
    menuChatHistory: "Chat History",
    menuChatSetting: "Chat Settings",
    menuInbox: "Chats",
    menuChatCredits: "Chat Credits",
    menuAddProduct: "Tambah Produk",
    menuEditProduct: "Edit Produk",
    menuSaveProduct: "Save",
    menuProductList: "Daftar Produk",
    menuCategory: "Category",
    menuSetting: "Settings",
    menuSettingStore: "Store Setup",
    menuSettingAdmin: "Administrator",
    menuSettingLiveChatCreate: "Create Live Chat",
    menuSettingLiveChatEdit: "Edit Live Chat",
    menuPlanPricing: "Subscription",
    menuCSPerformance: "Performance",
    menuSocial: "Social Channel",
    menuPublish: "Publish",
    menuComments: "Comments",
    menuCallCenter: "Call Center",
    menuCallCenterHistory: "Call History",
    menuEmail: "Email",

    menuChatSettingTitle: "CS Automatic Chat Settings",
    menuChatSettingDesc:
      "You can manage automatic chat replies to your customers.",
    menuChatbotDesc:
      "Chatbot auto reply fee is {0} chat credits/conversation in 24 hours (11% VAT is included).",

    menuChatSettingPriceDesc:
      "Fee for every chat reply is {0} chat credits/chat and only apply to chats from whatsapp only.",

    menuChatbotActivation: "Activate chatbot",
    menuChatSettingAutoActivation: "Activate automatic chat",
    menuChatSettingAutoChatActivationBanner:
      " To use automatic chat, you have to deactivate chatbot first in chatbot menu.",
    menuChatSettingAutoChatIsActive: "Active",
    menuChatSettingAutoChatIsInactive: "Inactive ",
    menuChatSettingAutoActivationOperationalHour: "Activate automatic chat",
    menuChatSettingAutoActivationFollowUp: "Activate follow up chat",
    menuChatSettingAutoActivationAutoClose: "Activate Auto Close",
    menuChatSettingSurveyRating: "Send Survey Rating",
    menuWallet: "Wallet",
    menuChatSettingAutoClosePackageBanner:
      "Feature will be turned off automatically if account status is Free",
    menuChatSettingAutoCloseInsufficientCreditBanner:
      "Feature will be turned off automatically if chat credit is insufficient",

    menuBroadcastTitle: "Broadcast Message",
    menuChatbotTitle: "Chatbot",
    menuChatCreditsTitle: "Chat Credits",
    menuChatCreditsDesc:
      "Have Chat Credits for the cost of sending messages to customers. Top up and see the total usage on the mutation page.",
    menuChatCreditsBonusAlertTitle: "Chat Credit Bonus up to {amount}!",
    menuChatCreditsBonusAlertDesc:
      "With a premium KOALA+ subscription, your chat credits will be top up to a total of {amount} each month.",
    menuChatCreditsBonusUpgradePremium: "Upgrade Premium",

    menuChatCreditsBalanceLabel: "Balance",

    menuChatCreditsTopUpAlertDesc:
      "Top up has not been paid. Please make a payment of {amount} now.",
    menuChatCreditsTopUpAlertButton: "Pay",

    menuChatCreditsFilterDateLabel: "Date",
    menuChatCreditsFilterMutationTypeLabel: "Mutation Type",

    menuTransactionAgentLabel: "Agent",

    menuCSPerformanceGeneralTab: "General",
    menuCSPerformanceAgentPerformanceTab: "CS Agent Performance Report",
    menuCSPerformanceCustomerSatisfactionTab: "Customer Satisfaction Report",
    menuCSPerformanceAgentStatusTitle: "Current Agent Status",
    menuCSPerformanceChatPerformanceTitle: "Chat Performance",
    menuCSPerformanceAnalysisTitle: "Analysis",
    menuCSPerformanceBroadcastPerformanceTab: "Broadcast Performance",
    menuCSPerformanceTemplateFilter: "Template",
    menuCSPerformanceBroadcastHistoryTab: "Broadcast History",

    menuCSPerformanceChannelFilter: "Channel",
    menuCSPerformanceDateFilter: "Period",

    menuContact: "Contacts",

    broadcastReceiverTitle: "Broadcast Recipients",
    broadcastReceiverListTitle: "Broadcast Recipient List",
    broadcastReceiverSubTitle: "Max quota to send is {0} contacts/day.",
    broadcastReceiverSubTitleTooltips: "Max quota to send is {0} contacts/day.",
    broadcastReceiverCounter: " recipient(s)",
    broadcastReceiverCounterSelection: " selected",
    broadcastReceiverListReveal: "Show All",
    broadcastReceiverListConceal: "Close",
    broadcastReceiverSelectedAll: "select all",
    broadcastReceiverSelectedCancel: "cancel",
    broadcastReceiverDeleteAllConfirmTitle: "Delete Recipient List?",
    broadcastReceiverDeleteAllConfirmContent:
      "You will delete all recipient numbers on the list",
    broadcastReceiverDeleteAllConfirmYes: "Yes, delete",
    broadcastReceiverDeleteAllConfirmNo: "No",
    broadcastReceiverDeletePartialConfirmTitle: "Delete Recipient List?",
    broadcastReceiverDeletePartialConfirmContent:
      "You will delete the selected recipient numbers on the list",
    broadcastReceiverDeletePartialConfirmYes: "Yes, delete",
    broadcastReceiverDeletePartialConfirmNo: "No",
    broadcastReceiverEmptyReceiver:
      "Broadcast recipient list is still empty. Please add contact or upload it in an Excel file",

    broadcastDetailTitle: "Broadcast Detail",
    broadcastDetailTemplate: "Template to use",
    broadcastDetailTemplateSelection: "Select Template",
    broadcastDetailSend: "Send Broadcast",
    broadcastDetailStoreName: "Store Name",
    broadcastDetailStoreNameEmpty: "Please setup store profile",
    broadcastDetailStoreLink: "Store Links",
    broadcastDetailDescription: "Note",
    broadcastDetailDescriptionPlaceholder:
      "Enter your note. Examples: new customers, regulars, Ramadan promos, etc.",
    broadcastDetailDescriptionSubtitle:
      "It won’t appear on the broadcast message. You can add note as a tag on the broadcast history.",
    broadcastDetailUploadPicture: "Upload an image",
    broadcastDetailUploadPictureSubtitle1: "Photos max 2MB and 500x500 pixels.",
    broadcastDetailUploadPictureSubtitle2:
      "You can replace the image by clicking 'Replace'",
    broadcastDetailUploadPictureInvalid: "Gambar tidak sesuai dengan ketentuan",
    broadcastDetailPreview: "Preview broadcast",
    broadcastDetailPreviewSubtitle:
      "View the broadcast chat you're going to send",
    broadcastDetailProduct: "Translate this page",
    broadcastDetailProductPlaceholder: "Choose a product from the catalog",
    broadcastDetailProductEmpty: "Add product to catalog",
    broadcastDetailBroadcastTime: "Time to broadcast",
    broadcastDetailBroadcastTimeNow: "Send broadcast now",
    broadcastDetailBroadcastTimeScheduled: "Schedule for later",
    broadcastDetailScheduleBroadcastDate: "Select date",
    broadcastDetailScheduleBroadcastTime: "Select time",
    broadcastDetailTemplatePlaceHolderEmptyReceiver:
      "Tambah kontak penerima terlebih dahulu",
    broadcastDetailSentConfirmTitle: "Confirmation to send broadcast",
    broadcastDetailSentConfirmSubtitlePrepaid:
      "You will send broadcast message to <b>{0} contact(s)</b> via {1}.",
    broadcastScheduledDetailSentConfirmSubtitlePrepaid:
      "You will send broadcast message to <b>{0} contact(s)</b> via {1} on {2} at {3}.",
    broadcastDetailSentConfirmSubtitlePostpaid:
      "You will send broadcast message to <b>{0} contact(s)</b> via {1}.",
    broadcastScheduledDetailSentConfirmSubtitlePostpaid:
      "You will send broadcast message to <b>{0} contact(s)</b> via {1} on {2} at {3}.",
    broadcastDetailMultipleCountrySentConfirmTitle:
      "Send broadcast to different countries",
    broadcastDetailMultipleCountrySentConfirmSubtitle:
      "Recipient's numbers are detected from <b>more than 1 country</b>. Broadcast price may change depending on the country.",
    broadcastScheduledMultipleCountryDetailSentConfirmSubtitle:
      "Recipient's numbers are detected from <b>more than 1 country</b>. Broadcast price may change depending on the country. Broadcast will be sent on {1} at {2}. Broadcast price may change depending on the country.",
    broadcastDetailSentConfirmYes: "Yes, send now",
    broadcastDetailSentConfirmNo: "No, cancel",
    broadcastSentMessageSuccess:
      "Successfully sent broadcast to {0} contact(s). Check history for details.",
    broadcastSentMessageFailed:
      "Failed sent broadcast message to {0} contacts.",
    broadcastSentInsufficientTitle: "Chat Credits Are Not Enough",
    broadcastSentInsufficientSubtitle:
      "Please top up to continue sending broadcast messages",
    broadcastSentExceedTitle: "Broadcast quota is exceed",
    broadcastSentExceedSubTitle:
      "Daily quota has run out. Please try again tomorrow.",
    broadcastTimeInvalid: "Selected time has passed current time.",
    broadcastScheduledMessageSuccess:
      "You will send broadcast to {0} contacts on selected time. Please check the broadcast history to see the sent broadcast detail",
    broadcastDialogTitleText: "Are you sure about removing this customer?",
    broadcastDialogTitleDescription:
      "This customer info will be removed from the broadcast list.",
    broadcastDialogCancelButtonText: "Cancel",
    broadcastDialogRemoveButtonText: "Yes, remove",

    templatePageTitle: "Broadcast Template",
    templateListTitle: "Template Broadcast List",
    templateListSearch: "Search Template Name",
    templateListRowCount: "Show",
    templateListRowCountOptionAll: "All",
    templateHeaderName: "Template Name",
    templateHeaderCampaignCode: "Campaign Code",
    templateHeaderType: "Template Type",
    templateHeaderContent: "Template Message",
    templateHeaderNewMessage: "New Message",
    templateHeaderStatus: "Status",
    templateHeaderAction: "Action",
    templateTableStatusApproved: "Approved",
    templateTableStatusDeclined: "Template rejected",
    templateStatusDeclinedExpl: "To get approved, please edit following the",
    templateTableStatusPending: "In review by Meta",
    templateTableEdit: "Edit",
    templateTableDelete: "Delete",
    templateGuide: "guideline",
    templatePreview: "Preview",
    templatePreviewbutton: "Preview",
    templateSubmitNew: "Add New Template",

    chatSettingSurveyRatingRemarksSelectRemarks: "Select remarks...",
    chatSettingSurveyRatingTemplateSelectTemplate: "Select template...",
    chatSettingSurveyRatingCSTagsSelectCSTag: "Select CS Tags...",
    createTemplateFormName: "Template name",
    createTemplateFormNameSubtext:
      "Template name is only for labeling purpose and it won’t appear on the broadcast message",
    createTemplateFormLanguageSubtext: "Select language for your template",
    createTemplateFormNamePlaceholder:
      "Enter template name. Example: Product promo, end year discount, etc.",
    createTemplateFormLanguagePlaceholder: "Select language",
    createTemplateFormHeaderType: "Header type",
    createtemplateFormOptional: "Optional",
    createTemplateFormHeaderTypeSubtext:
      "Header type can be a title text or media such as image or video",
    createTemplateFormHeaderTypePlaceholder: "Select header type",
    createTemplateFormTextContent: "Template message",
    createTemplateFormTextGuide: "How to get your template approved",
    createTemplateFormTextContentPlaceholder: "Write your message here",
    createTemplateFormCTAPhone: "Button to make a phone call",
    createTemplateFormCTAPhoneSubtext:
      "Want the recipients to contact you directly? Add a button to call your number so that they can learn more about your products or services.",
    createTemplateFormCTAPhoneTextPlaceholder:
      "Enter button text, example: Contact us",
    createTemplateFormCTAWebsite: "Button to open a website",
    createTemplateFormCTAWebsiteSubtext:
      "Want the recipients to open your webstore? Add a button to visit your website so they can easily find more info about your products or services.",
    createTemplateFormCTAWebsiteTextPlaceholder:
      "Enter button text, example: Learn more",
    createTemplateFormCTAWebsiteContentPlaceholder:
      "Enter link to website or product",
    createTemplateSubmitDisclaimerContinueButton: "Ok, continue",
    createTemplateSubmitDisclaimerLaterButton: "Submit later",
    createTemplateSubmitSuccess:
      "Successfully create new template. Please wait max. 24 hours.",
    createTemplatePreview: "Preview template",
    createTemplateSubmit: "Add template",
    createTemplateNext: "Next",
    createTemplateTitle: "Add New Template",
    createTemplateFormHeaderTypeNone: "Select header type",
    createTemplateFormHeaderTypeText: "Text title",
    createTemplateFormHeaderTypeMedia: "Media",
    createTemplateFormHeaderText: "Text title",
    createTemplateFormHeaderTextPlaceholder: "Enter text title",
    createTemplateFormHeaderTextError: "Can't be more than 150 characters",
    EditTemplateSubmit: "Edit template",
    createTemplateFormRequiredError: "is required",

    createTemplateFormHeaderMedia: "Select media type",
    createTemplateFormUpload: "Upload",
    createTemplateFormHeaderMediaSubtext1:
      "For image: use png, jpeg, or bmp with 500x500px and max size is 16MB",
    createTemplateFormHeaderMediaSubtext2: "For video: use mp4, avi, mkv, 3gp",
    createTemplateFormHeaderMediaSubtext3: "For document: use pdf",
    createTemplateFormHeaderMediaImage: "Image",
    createTemplateFormHeaderMediaVideo: "Video",
    createTemplateFormHeaderMediaDocument: "Document",
    createTemplateFormHeaderMediaError:
      "The file format or size does not match the rules. Please upload again",
    createTemplateFormHeaderMediaErrorNoFile: "Please upload your file",
    createTemplateFormHeaderMediaErrorUpload: "Max size is 16MB",
    createTemplateFormHeaderMediaGeneralErrorUpload:
      "Something went wrong. Please try again.",

    createTemplateFormCTAPhoneTextCountryCodeError:
      "Phone number must be started with 62",
    createTemplateFormCTAPhoneTextLengthError:
      "Phone number's length should be 9-13 numbers",
    createTemplateFormCTATextEmptyError: "You must fill button name",
    createTemplateFormCTAPhoneContentPlaceholder: "Phone number",
    seeHowToCategoryDetailGuide: "How to choose category",
    createTemplateFormCTAPhoneTextExceededError:
      "Can't be more than 25 characters",
    createTemplateFormCTAPhoneTextFormatError: "Invalid phone number format",
    createTemplateFormCTAWebsiteTextExceededError:
      "Can't be more than 25 characters",
    createTemplateFormTextContentExceededError:
      "Can't be more than 1024 characters",
    createTemplateFormTextContentEmptyError: "You must fill template message",

    freeAccountSubscribeAction: "Subscribe now",
    freeAccountSubscribeActionAlt1: "Upgrade",
    freeAccountSubscribe:
      "By subscribing now, you can use this feature to support your sales",
    freeAccountSubscribeAlt1:
      "Masa free trial telah berakhir. Yuk, upgrade akunmu sekarang agar\nbisa terus pakai {0}!",
    freeAccountSubscribeAlt2:
      "Upgrade your account to Premium by connecting your {0} account to WABA account",
    freeAccountSubscribeBroadcast:
      "By subscribing now, you can use the broadcast feature to promote your store",
    freeAccountSubscribeTemplate:
      "By subscribing now, you can use the template feature to promote your store",
    freeAccountSubscribeChatbot:
      "By subscribing now, you can use the chatbot feature to promote your store",
    freeAccountSubscribeChatCredits:
      "Dengan berlangganan sekarang, kamu bisa menggunakan fitur chat credits untuk mempromosikan tokomu",
    freeAccountLoadingText:
      "Currently preparing features for free trial. Wait a minute, please.",
    freeAccountFailedLoadingText:
      "Sorry, failed to load free trial features. Reload the page, please.",
    freeAccountReloadText: "Reload",

    accountLogout: "Log out",
    accountUpgrade: "Upgrade",
    accountUserType: "User Type",
    accountSubscriptionEnd: "Expired on ",

    chatCreditBalance: "Total Balance",
    chatCreditBill: "{0} Total Bill",
    chatCreditUnit: "Chat Credits",
    chatCreditMainBalance: "Main Balance",
    chatCreditMainBalanceDescription: "For inbound & outbound message",
    chatCreditBonusBalance: "Inbound Bonus Balance",
    chatCreditBonusBalanceDescription: "Only for inbound messages",
    chatCreditInboundMessage: "inbound message",
    chatCreditOutboundMessage: "outbound message",
    chatCreditMutationCheck: "See Details",
    chatCreditMainBalanceAlertEmpty: "Top up first to send messages.",
    charCreditMainBalanceAlertAlmostEmpty:
      "Balance is running low. Let's top up!",
    chatCreditTopUp: "Top Up",
    charCreditBonusBalanceCheckDetail: "Learn more",

    chatCreditTopUpSuccessLabel: "Berhasil Top Up",
    chatCreditTopUpFailedLabel: "Gagal Top Up",

    chatCreditTopUpAmount: "top up amount",
    chatSettingSurveyRatingTemplateSurveyDetail:
      "Create your template first at Template page. only meta approved template that can be used",
    chatSettingSurveyRatingRemarksSurveyDetail:
      "Create your remarks first at Remark page. only message that are closed by selected remark will be sent a survey",
    chatSettingSurveyRatingCSTagsSurveyDetail:
      "Chat that handled by certain tags will be sent a survey",
    paymentTotal: "Payment Total",

    paymentSubtotal: "Subtotal",
    productSearch: "Search product...",

    menuWalletTitle: "Wallet",

    walletMutationTab: "Mutasi",
    walletPendingTab: "Pending",
    walletDetail:
      "Pantau mutasi dan saldo wallet tokomu. Pemasukan akan otomatis masuk ke rekening kamu maksimal 1x24 jam sejak transaksi selesai",
    walletMutationAutoDisbursementInfo: "Tentang Pencairan Dana Otomatis",
    walletMutationAutoDisbursementAlertTitle:
      "Pencairan Dana Otomatis Belum Aktif",
    walletMutationAutoDisbursementAlertDescription:
      "Silakan tambah rekening untuk mengaktifkan pencairan dana otomatis.",
    walletMutationTitle: "Mutasi",
    walletPendingTitle: "Saldo Pending",
    walletBankAccountCreate: "Tambah rekening",
    walletBankAccountUpdate: "Ubah",
    walletBankAccountDestination: "Rekening tujuan",
    walletBankAccountAlertTitle:
      "Selamat, pencairan dana otomatis sudah aktif!",
    walletBankAccountAlertDescription:
      "Kamu sudah menambahkan no. rekening, sehingga dana penjualanmu akan masuk ke rekening tujuan secara otomatis sesuai dengan jadwal.",
    walletBankAccountDescription: "Cairkan dana otomatis ke rekeningmu",
    walletTableHeaderCreatedAt: "Waktu",
    walletTableHeaderDescription: "Deskripsi Transaksi",
    walletTableHeaderMutation: "Mutasi",
    walletTableHeaderSaldo: "Saldo Wallet",
    walletTableHeaderPendingAmount: "Jumlah",
    walletTableHeaderPendingStatus: "Status",
    walletTableHeaderPendingStatusInProgress: "Diproses",
    WalletTableHeaderPendingCreatedAt: "Estimasi Cair",

    menuContactTitle: "Contact",
    menuAddContact: "Add Contact",
    contactDetail: "Manage contacts to set up a targeted message delivery",
    contactTagLabel: "Customer Groups",
    contactTab: "Customer List",
    contactGroupTab: "Customer Groups",
    contactAddNewContact: "Add Contact",
    contactAddNewSingleContact: "Add Single Contact",
    contactAddNewBulkContact: "Add Bulk Contact",
    contactAddNewCustomField: "Fields Setting",
    coachmarkButtonAddCustomField:
      "Now you can add and manage fields for your contacts information!",
    labelContactSearch: "Search contact name",
    labelContactFilterByTag: "Filter Tag",
    labelContactFilterByChannel: "Filter Channel",
    labelContactNoDataHeader: "Add customer's contact start here",
    labelContactNoDataDescription:
      "Send broadcast messages to your customer faster than before from your contacts.",
    labelContactNotFoundHeader: "Contact is not found",
    labelContactNotFoundDescription: "Try using another keyword",
    labelContactHeaderName: "Name",
    labelContactHeaderContact: "Contact",
    labelContactHeaderChannel: "Channel",
    labelContactHeaderTag: "Customer Group",
    labelContactHeaderAverageTransaction: "Average transaction",
    tooltipAverageTransaction:
      "Total item price from all paid transactions divided by total number of paid transactions.",
    labelContactHeaderAction: "Action",
    labelAddContactHeaderTitle: "Add / Edit Contact",
    labelEditContactHeaderTitle: "Edit Contact",
    labelRadioAddContactBulk: "Add bulk contacts",
    labelRadioAddContactSingle: "Add contact one by one",
    labelAddContactBulk: "Add bulk contacts",
    labelAddContactSingle: "Add one contact",
    labelContactTagContinueButton: "Skip",
    labelContactTagSaveButton: "Save",
    labelContactListXMore: "+ {0} more",
    labelBroadcastSelectedContact: "Selected Contacts",
    labelDownloadTemplateContactBulk:
      "Download the Excel template file of Contacts, then fill in the details. Please don't add or delete the column and ensure the file format is not changed",
    labelDownloadTemplateContact: "Download template file",
    labelUploadContactBulk:
      "Upload or drag the filled template file to the area below. After successful upload, click Save.",
    labelUploadContact: "Upload File",
    labelUploadContactExceedLimit: "Maksimum 2000 contacts/upload",
    labelUploadContactExceedFileLimit: "File size cannot exceed 5MB",
    labelUploadContactWrongFormat: "Template must be .xslx.",
    labelUploadContactExceedLimitError:
      "Maksimum 2000 contacts/upload. Make sure doesn't exceed 2000.",
    labelContactUploadSuccess: "File uploaded successfully",
    labelContactUploadError: "Error when processing file",
    labelContactUploadFileExtensionDetail: `{0} file with {1} only`,
    labelContactUploadFileTypeImage: "Image",
    labelContactUploadFileTypeDocument: "Document",
    labelContactChangeFile: "Do you want to change the file?",
    labelContactReuploadFile: "Upload another data?",
    labelContactSuccessfullyProcessedContact:
      "Successfully processed {0} from {1} contacts",
    labelContactReupload: "Re-upload here",
    labelContactUploadInvalidNumber: `There are {0} invalid contact numbers, <a href='{1}' style="color: black; text-decoration: underline">Download invalid numbers list.</a>`,
    labelContactUploadExistingNumber: `<br/>There is {0} saved numbers in the database. The previous number will be changed to the new one.`,
    labelAddContactValidationFileRequired: "Please upload file first.",

    labelUploadContactDropZoneCannotChangeFileFormat:
      "Don't change the file format.",
    labelUploadContactDropZoneMaxLimit: "Maximum file size is 5 MB",

    contactUploadAlertButton: "Refresh",
    contactCompleteUploadAlertButton: "Completed",
    contactUploadAlertOnProgressDescription:
      "Upload data is in progress. Please wait for the process to complete",
    contactUploadAlertProcessedDescription: "Upload data is complete",
    contactDialogUploadButtonText: "Upload now",
    contactDialogUploadTitleText: "Upload Contact Data",
    contactDialogUploadTitleDescription:
      "Please make sure all of the data is correct.",
    contactBulkTitle: "Contact List",
    contactBulkCounter: " receiver",
    contactBulkCounterSelection: " selected",
    contactBulkSelectedAll: "select all",
    contactBulkSelectedCancel: "cancel",
    contactBulkListReveal: "See more",
    contactBulkListConceal: "Close",
    contactBulkDeleteAllConfirmTitle: "Delete Contact List?",
    contactBulkDeleteAllConfirmContent:
      "You will delete all contact on the list",
    contactBulkDeleteAllConfirmNo: "No",
    contactBulkDeleteAllConfirmYes: "Yes, delete",
    contactBulkDeletePartialConfirmTitle: "Delete Contact List?",
    contactBulkDeletePartialConfirmContent:
      "You will delete selected contact on the list",
    contactBulkDeletePartialConfirmYes: "Yes, delete",
    contactBulkDeletePartialConfirmNo: "No",
    contactBulkPhoneNumberHeader: "Phone number with country code (required)",
    contactBulkNameHeader: "Name (required)",
    contactBulkGroupHeader: "Group name (optional)",
    contactSubmitNewContact: "Save",
    labelAddContactOptional: "Optional",
    labelContactTagAddName: "Insert Customer Group Name",
    labelErrorMessageDuplicateContactTag:
      "Customer group name already registered",
    labelAddContactDetailContactName: "Name",
    labelAddContactDetailContactNameHint: "Insert customer name",
    labelAddContactErrorEmptyName: "Name must be filled",
    labelAddContactDetailContactChannelHint: "Insert {0} contact",
    labelErrorMessageContactNameMaxCharacter:
      "Customer name maximum 150 characters",
    labelErrorMessageContactNameRequired: "Customer name is required",
    labelAddContactDetailContactNumber: "WhatsApp Number",
    labelAddContactDetailContactNumberHint:
      "Input customer's active WhatsApp number",
    labelErrorMessageContactNumberInvalidFormat: "Incorrect number format",
    labelErrorMessageContactNumberDuplicate:
      "This number is already stored in database.",
    labelErrorMessageGroupNameMaxCharacter: "Group name maximum 30 characters",
    labelErrorMessageGroupNameRequired: "Group name is required",
    labelErrorMessageGroupDescriptionMaxCharacter:
      "Group description maximum 300 characters",
    labelErrorMessageGroupDescriptionRequired: "Group description is required",
    labelErrorMessageContactNumberLength: "Minimum number {0} - {1} digits",
    labelAddContactDetailContactTag: "Customer Groups",
    labelAddContactDetailContactTagHint: "Select customer group",
    labelAddContactDetailAddContactTagText: "Add new group",
    labelContactMultipleDeleteConfirmationTitle: "You will delete {0} contact.",
    labelContactDelete: "Delete",
    labelContactDeleteConfirmationTitle: "Delete this contacts?",
    labelContactDeleteCancelLabel: "Cancel",
    labelContactDeleteConfirmLabel: "Yes, delete",

    contactSuccessAddText: "Successfully added {0} contacts",
    contactSuccessUpdateText: "Contact changes have been saved",
    contactSuccessRemoveText: "Contact has been removed",
    contactFailedAddText: "Failed added {0} contacts",
    contactFailedAddTextDuplicateHint:
      "Duplicate contact. Delete the existing contact ({0}) to update",
    contactFailedUpdateText: "Failed updated contact",
    contactFailedRemoveText: "Failed removed {0} contacts",
    contactSelectedContactText: "{0} contacts have been selected",
    contactDialogCancelButtonText: "Cancel",
    contactDialogRemoveButtonText: "Yes, delete",
    contactDialogTitleText:
      "Are you sure you want to remove this customer contact?",
    contactDialogTitleDescription:
      "This contact info will be removed from the customer list.",
    contactDetailPreviousPageButton: "Back to Contact",
    contactDetailInfoDetail: "Contact Details",
    contactDetailTagMore: "+ {0] more",
    contactDetailTagGroup: "Customer Group",
    contactDetailEdit: "Edit Contact",
    contactDetailDelete: "Delete Contact",
    contactDetailChatHistoryTitle: "Chat History",
    contactDetailContinueChatButton: "Continue conversation",
    contactDetailChatListSeeDetail: "See chat",
    contactDetailChatColumnHeaderDate: "Date",
    contactDetailChatColumnHeaderChat: "Chat",
    contactDetailChatColumnHeaderStatus: "Status",
    contactDetailChatColumnHeaderAction: "Action",
    contactDetailChatResolvedStatus: "Resolved",
    contactDetailChatOngoingStatus: "On going",
    contactDetailChatEmptyTitle: "You don't have any chat history",
    contactDetailChatEmptySubtitle:
      "Start chat your customer for a better relation management",
    contactDetailChatEmptyActionButtonLabel: "Chat your customer",
    contactDetailTransactionTitle: "Transaction history",
    contactDetailTransactionDetail: "Transaction Details",
    contactDetailTransactionListSeeDetail: "See Detail",
    contactDetailTransactionProductMore: "+{0} products more",
    contactDetailTransactionColumnHeaderDate: "Date",
    contactDetailTransactionColumnHeaderTransactionNo: "Transaction Number",
    contactDetailTransactionColumnHeaderItem: "Item",
    contactDetailTransactionColumnHeaderTotal: "Total",
    contactDetailTransactionColumnHeaderAction: "Action",
    contactDetailBroadcastEmptyActionButtonLabel: "Broadcast",
    contactDetailBroadcastTitle: "Broadcast history",
    contactDetailBroadcastColumnHeaderDate: "Date",
    contactDetailBroadcastColumnHeaderTemplate: "Template Broadcast",
    contactDetailBroadcastColumnHeaderStatus: "Status",
    contactDetailBroadcastColumnHeaderStatusFail: "Fail",
    contactDetailBroadcastColumnHeaderStatusDelivered: "Delivered",
    contactDetailBroadcastColumnHeaderStatusRead: "Read",
    contactDetailBroadcastColumnHeaderAction: "Action",
    contactDetailBroadcastListSeeDetail: "View Template",

    contactDetailTransactionEmptyTitle: "No transactions yet",
    contactDetailTransactionEmptySubtitle:
      "All transactions will be shown here. You can make a new one using transaction feature within the chat",

    contactDetailBroadcastEmptyTitle: "No broadcasts yet",
    contactDetailBroadcastEmptySubtitle:
      "All broadcasts will be shown here. You can make a new one using broadcast feature",

    contactTagAddDialogHeader: "Add Tag",
    contactTagDialogLaterButton: "Later",
    contactTagDialogSaveButton: "Add",
    contactTagSuccessAddText: "Successfully added customer groups",
    contactTagSuccessUpdateText: "Successfully edited the customer group",
    contactTagSuccessRemoveText: "Success remove group",
    contactTagDialogCancelButtonText: "Cancel",
    contactTagDialogRemoveButtonText: "Yes, delete",
    contactTagDialogTitleText: "Remove {0}",
    contactTagDialogTitleDescription:
      "This group info will be removed from customer group lists.",

    labelRadioChooseContactBulk: "Add bulk contacts",
    labelRadioChooseContactSingle: "Add contact from database",
    labelDownloadTemplateContactTagBulk:
      "Download Customer Data Template and complete the info. Don't add/remove any column nor change the file format.",
    labelDownloadTemplateContactTag: "Customer's Data Template",
    labelUploadContactTagBulk:
      "Upload or drag the filled template to this area below.",

    labelUploadContactTagDropZoneCannotChangeFileFormat:
      "Don't change the file format.",
    labelUploadContactTagDropZoneMaxLimit: "Maximum file size is 5 MB",
    labelContactTagUploadSuccess: "Successfully uploaded the file",
    labelContactTagChangeFile: "Upload another file?",
    labelContactTagReupload: "Click here",

    labelContactTagSearch: "Search group name",
    contactTagAddNewContact: "Add Group",
    contactSubmitNewContactTag: "Save",

    labelContactTagHeaderName: "Group name",
    labelContactTagHeaderContact: "Total",
    labelContactTagTotalContact: "contacts",
    labelContactTagHeaderAction: "Send Broadcast",
    labelContactTagNoDataHeader: "Let's add customer group",
    labelContactTagNoDataDescription:
      "Manage customer groups so you can send broadcast messages in line with your goals.",
    labelContactTagNotFoundHeader: "Group is not found",
    labelContactTagNotFoundDescription: "Try using another keyword",
    labelContactTagBroadcast: "Send Broadcast",
    labelAddContactTagHeaderTitle: "Input the group details",
    contactTagDetailEdit: "Edit",
    contactTagDetailSaveEdit: "Next",
    labelChooseContactHeaderTitle: "Choose Contact",
    labelAddContactTagDetailGroupName: "Group Name",
    labelAddContactTagDetailGroupNameHint: "Input group name",
    labelAddContactTagDetailGroupDescription: "Description",
    labelAddContactTagDetailGroupDescriptionHint:
      "Input customer group description",
    labelErrorMessageContactTagGroupDuplicate:
      "This group name is already stored in the database",
    labelEditContactTagHeaderTitle: "Edit Group",
    labelContactTagDeleteConfirmationTitle: "Delete {0}?",

    menuCustomFieldTitle: "Field Setting",
    menuCustomFieldDetail:
      "Adjust your contact information by adding a new field and fill it on “Detail contact” page.",
    customFieldGuideButton: "See Guide",
    customFieldAddButton: "Add Field",
    customFieldAlertBanner:
      "You can hide unwanted field by turn off the toggle button on “Show” column",
    customFieldPreviewTitle: "Contact’s detail preview",
    customFieldGuideTitle: "How to fill the template",
    customFieldNameLabel: "Name",
    customFieldContactLabel: "Whatsapp Number",
    customFieldGroupLabel: "Customer Group",
    customFieldBirthdayLabel: "Date of Birth",
    customFieldEducationLabel: "Education",
    customFieldNameTableHeader: "Field name",
    customFieldTypeTableHeader: "Field type",
    customFieldDisplayTableHeader: "Show",
    customFieldExampleTableHeader: "Example",
    customFieldActionTableHeader: "Action",
    customFieldActionTable: "See Detail",
    customFieldAddComponentTitle: "Add field",
    customFieldAddComponentName: "Field detail",
    customFieldAddComponentNameHint: "Example: Email, Address, etc",
    customFieldAddComponentType: "Field type",
    customFieldAddComponentTypeHint: "Choose Field type",
    customFieldAddComponentOption: "Option {0}",
    customFieldAddComponentAddOption: "Add Option",
    customFieldAddComponentValidation: "Validation",
    customFieldAddComponentValidationHint: "Choose Validation",
    customFieldAddComponentValidationTooltip:
      "Selecting validation to prevent data errors",
    customFieldAddComponentGuideHint: "Field type example",
    customFieldAddComponentGuideReveal: "See detail",
    customFieldAddComponentGuideConceal: "Hide",
    customFieldAddComponentSaveButton: "Save",

    channelNameTableHeader: "Channel",
    channelDisplayTableHeader: "Show",
    channelActionTableHeader: "Action",
    channelActionIsMainChannelLabel: "Main channel",
    channelActionSetMainChannelLabel: "Set as main",
    mainChannelDialogTitle: "Change main Channel for Customer's Contacts",
    mainChannelDialogContent:
      "The main channel will be changed and will impact your contact data processing",
    mainChannelConfirmationDialogCancel: "Cancel",
    mainChannelConfirmationDialogConfirm: "OK, Make Primary",

    labelErrorMessageCustomFieldNameRequired: "Field name must be filled",
    labelErrorMessageCustomFieldNameDuplicate:
      "This name has been saved in database",
    labelErrorMessageCustomFieldTypeRequired: "Field type must be filled",
    labelErrorMessageCustomFieldOptionRequired: "This option must be filled",
    labelErrorMessageCustomFieldValidationRequired:
      "Data validation must be filled",

    customFieldAddDialogTitleText: "Add field",
    customFieldAddDialogTitleDescription:
      "You cannot delete the field <b>that has been added</b>, so please make sure the information is correct.",
    customFieldAddDialogCancelButton: "Check again",
    customFieldAddDialogApproveButton: "Add field",
    customFieldFileUploadButton: "Upload File",
    customFieldSuccessAddText: "Add field success",
    customFieldFailedAddText: "Failed add new field",

    customFieldDetailComponentName: "Detail field",
    customFieldSuccessUpdateText: "Field changes has been saved",
    customFieldFailedUpdateText: "Failed saved field changes",

    errorCustomFieldInvalidFormat: "Invalid format",
    errorCustomFieldInvalidFileSize: "File size must be smaller than 5MB",
    errorCustomFieldInternalServer: "Internal server error",

    customFieldBannerDescription:
      "Make your broadcast message more personalized with variable which automatically connected to your contact information.",
    customFieldBannerButton: "Check feature",

    menuContactBulkUploadTitle: "Add Bulk Contact",
    contactBulkUploadGuideHint: "Follow these steps to add bulk contact",
    contactBulkUploadGuideStep:
      "To learn how to fill the template, please check",
    contactBulkUploadGuideRef: "how to fill template.",
    contactBulkUploadGuideTitle: "How to fill the template",
    contactBulkUploadGuideStep1:
      "Make sure you have download a new template each time you want to add contact.",
    contactBulkUploadGuideStep2:
      "Do not add, change, or delete the column in the template.",
    contactBulkUploadGuideStep3: "Make sure the file format is",
    contactBulkUploadGuideStep4:
      "Data with the same WhatsApp number, will be updated based on the newest data.",
    contactBulkUploadDownloadTitle: "Download template",
    contactBulkUploadDownloadDescription:
      "Click the button bellow to download the template",
    contactBulkUploadUploadTitle: "Fill & upload template",
    contactBulkUploadUploadDescription:
      "Fill the information on the template then upload. Make sure the file format is <b>.xlsx</b>.",
    contactBulkUploadUploadButton: "Upload template",
    contactBulkUploadUploadTooltipLabel:
      "Please wait until we finish processing the file",

    menuContactBulkUploadHistoryTitle: "Upload History",
    contactBulkUploadHistoryUploadTimeLabel: "Upload time",
    contactBulkUploadHistoryStatusLabel: "Status",
    contactBulkUploadHistoryFilenameLabel: "File name",
    contactBulkUploadHistorySavedLabel: "Saved",
    contactBulkUploadHistoryFailedLabel: "Failed",
    contactBulkUploadHistoryActionLabel: "Action",

    contactBulkUploadHistorySuccessChipLabel: "Success",
    contactBulkUploadHistorySuccessPartiallyChipLabel: "Success partially",
    contactBulkUploadHistoryFailedChipLabel: "Failed",
    contactBulkUploadHistoryProcessingChipLabel: "Processing",

    contactBulkUploadHistoryCounterLabel: "{0} contacts",
    contactBulkUploadHistoryDownloadLabel: "Download",
    contactBulkUploadHistoryDownloadTooltipLabel:
      "Download failed contact detail",

    contactBulkUploadHistoryInvalidTemplateLabel: "File format is not valid",
    contactBulkUploadHistoryEmptyLabel: "File is empty",
    contactBulkUploadHistoryNotFoundLabel: "File not found",
    contactBulkUploadHistoryErrorSystemLabel: "System error",
    contactBulkUploadHistoryNoDataLabel: "No data",

    categoryListTitle: "Daftar Kategori",
    categoryAddButton: "Tambah Kategori",
    categorySearch: "Cari nama kategori",
    categoryDisplay: "Show",
    categoryName: "Nama Kategori",
    categoryTableAction: "Action",
    categoryEmptyTitle: "Belum ada data kategori",
    categoryEmptyDescription:
      "Yuk buat kategori produk untuk memudahkan pembeli melihat-lihat produkmu di website jualan",
    categoryAddDialogHeader: "Tambah Kategori",
    categoryDialogLaterButton: "Nanti saja",
    categoryDialogSaveButton: "Add",
    categoryDialogCancelButton: "Cancel",
    categoryDialogDeleteButton: "Delete now",
    categoryDialogDeleteHeader: "Hapus Kategori",
    categoryDialogDeleteDescription:
      "Kamu akan menghapusnya dari Daftar Kategori.",
    categoryEditDialogHeader: "Edit Kategori",
    productListHeader: "Daftar Produk",
    productListDescription: "Lihat semua produk atau tambahkan disini",
    productAddNewProduct: "Tambah Produk",
    productAllProducts: "Semua Produk",
    whatsappCatalogueAllProducts: "All Product",
    whatsappCatalogueTemplateSPM: "SPM Template",
    productAvailableStatus: "Dijual",
    productNotAvailableStatus: "Tidak Dijual",

    labelProductSearch: "Cari nama produk",
    labelProductFilterByCategory: "Category Filter",
    labelProductHeaderPhoto: "Foto Produk",
    labelProductHeaderName: "Nama Produk",
    labelProductHeaderCategory: "Kategori",
    labelProductHeaderPrice: "Harga",
    labelProductHeaderDescription: "Deskripsi",
    labelProductHeaderActiveStatus: "Aktif",
    labelProductHeaderAction: "Action",

    labelProductActivateAll: "Aktifkan semua",
    labelProductDeactivateAll: "Non aktifkan semua",
    labelProductDelete: "Delete",
    labelProductNoDataHeader: "Belum ada produk",
    labelProductNoDataDescription:
      "Yuk tambah produk sekarang, biar website jualan kamu bisa langsung aktif.",

    labelProductDeleteConfirmationTitle: "Hapus produk ini?",
    labelProductDeleteConfirmationLabel:
      "Kamu akan menghapus {0} dari Daftar Produk.",
    labelProductDeleteCancelLabel: "Cancel",
    labelProductDeleteConfirmLabel: "Yes, delete",

    labelProductMultipleDeleteConfirmationTitle:
      "Kamu akan menghapus {0} produk.",

    labelProductMultipleActivationConfirmationTitle: "Ubah status stok?",
    labelProductMultipleActivationLabel:
      "Kamu akan merubah status stok barang dari {0} produk.",
    labelProductMultipleActivationConfirmLabel: "Ya",

    labelAddProductDetailHeaderTitle: "Detail Produk",
    labelAddProductDetailProductName: "Product name",
    labelAddProductDetailProductNameHint: "Isi nama produk",
    labelAddProductDetailProductCategory: "Kategori",
    labelAddProductDetailProductCategoryHint: "Pilih kategori",
    labelAddProductDetailProductAddCategoryText: "Tambah kategori baru",
    labelAddProductDetailProductPrice: "Product price",
    labelAddProductDetailProductPriceHint: "Isi harga produk",
    labelAddProductDetailProductType: "Tipe produk",
    labelAddProductDetailPhysicalProduct: "Fisik",
    labelAddProductDetailNonPhysicalProduct: "Non Fisik",
    labelAddProductDetailProductMeasurementUnit: "Satuan",
    labelAddProductDetailProductWeight: "Weight",
    labelAddProductDetailProductWeightHint: "Isi  berat",

    labelAddProductVariantHeaderTitle: "Varian ",
    labelAddProductOptional: "Opsional",
    labelAddProductVariantActivateButtonText: "Aktifkan Varian",
    labelAddProductVariantRemoveButtonText: "Hapus Grup Varian",
    labelAddProductVariantAddOptionText: "+ Tambah pilihan varian ({0}/10)",
    labelAddProductVariantMandatoryText: "Apa pelanggan wajib memilih varian?",
    labelAddProductVariantMultipleSelectionText:
      "Pelanggan bisa pilih beberapa varian?",
    labelAddProductVariantGroupAddOptionText: "Tambah grup varian ({0}/3)",

    labelAddProductDetailVariantGroupName: "Nama grup varian",
    labelAddProductDetailVariantGroupNameHint: "Isi nama grup varian",
    labelAddProductDetailVariantGroupDescription:
      "Contoh: Ukuran, Warna, Bahan, dll",
    labelAddProductDetailVariantOptions: "Pilihan varian",
    labelAddProductDetailVariantOptionsDescription1:
      "Harga tambahan adalah harga satuan produk ditambah dengan harga varian",
    labelAddProductDetailVariantOptionsDescription2:
      "Toggle on/off untuk mengatur ketersediaan produk",
    labelAddProductDetailVariantOptionNameHint: "Contoh: Hitam",
    labelAddProductDetailVariantOptionPriceHint: "jika gratis, isi 0",
    labelAddProductDetailVariantOptionalConfirm: "Ya",
    labelAddProductDetailVariantOptionalDeny: "Tidak",
    labelAddProductDetailVariantSingleSelect: "Hanya 1 pilihan",
    labelAddProductDetailVariantMultipleSelect: "Banyak pilihan",

    labelAddProductImageHeaderTitle: "Foto Produk",

    labelProductDescriptionHeaderTitle: "Deskripsi Produk",
    labelAddProductDescriptionHint: "Ketik deskripsi barang di sini",
    labelAddProductAddButton: "Add product",
    labelAddVariant: "Add variant",
    labelAddVariantManual: "Add variant manually",
    labelAddVariantName: "Variant name",
    labelAddVariantPrice: "Variant price",
    labelAddVariantNameManual: "Additional variant",
    labelAddVariantSuccess: "Success adding variant.",
    labelEditVariant: "Edit variant",
    labelVariantRequired: "Required",
    labelVariantOptional: "Optional",
    labelVariantTitle: "Variant not found?",
    labelVariantDesc: "Please add variant",
    labelVariantManual: "manually",
    labelPlaceholderNote: "Put note for seller",
    labelAddProductSuccess: "Produk berhasil ditambahkan",

    labelRecheck: "Re-check",
    labelRecheckTitle: "Please re-check your product",
    labelRecheckDescription:
      "If you have added the product, then you will not be able to change variant and note",

    settingAdmin: "Admin & Users",
    settingAdminDetail: "You can manage roles and task of your team members",
    settingAdminList: "User List",
    settingAdminAdd: "Add User",
    settingAdminInfo:
      "Maximum add {0} users. The {1}th user is charged Rp. 199,000/month. To apply for additions, please email to business@koalaapp.id",
    settingFeaturesAccessList: "Access List",
    settingFeaturesAccessDetail: `Configure Access Menu according to the needs of each role when accessing {0}`,
    settingFeaturesAccessTableHeaderName: "Name",
    settingFeaturesAccessTableHeaderDesc: "Description",
    settingFeaturesAccessAddButton: "Add Role",
    settingFeaturesAccessTableHeaderAction: "Action",
    settingFeaturesAccessDeleteConfirm: "Yes, Remove",
    settingFeaturesAccessDeleteTitle: "Remove List Access?",
    settingFeaturesAccessDeleteContent:
      "Make sure no User Applied to this Access",
    settingFeaturesAccessDeleteCancel: "No",
    settingFeaturesAccessFormNameLabel: "Role Name",
    settingFeaturesAccessFormNamePlaceHolder: "Fill role name",
    settingFeaturesAccessFormNameValidationErrorEmpty: "Name is Required",
    settingFeaturesAccessFormNameValidationErrorLength: "Max 15 Characters",
    settingFeaturesAccessSuccessCreateUpdateMessage: "Role Save Successfully",
    settingFeaturesAccessFailedCreateUpdateMessage: "Failed Saving Role, {0}",
    settingFeaturesAccessSuccessDeleteMessage: "Role Deleted",
    settingFeaturesAccessFailedDeleteMessage: "Failed Deleting Role, {0}",
    settingFeaturesAccessFailedGetList: "Failed Load Access List",
    settingFeaturesAccessFormDescLabel: "Description",
    settingFeaturesAccessFormDescPlaceHolder: "Fill role description",
    settingFeaturesAccessFormFeaturesLabel: "Menu Access",
    settingFeaturesAccessFormAllMenuToogleLabel: "All Menu",
    settingFeaturesAccessDialogCreateButton: "Add",
    settingFeaturesAccessDialogUpdateButton: "Save",
    settingAdminSearchPlaceholder: "Search name or email",
    settingAdminFilterPlaceholder: "Filter role",
    partnerSettingAdminInfo: "Bisa tambah anggota tim hingga {0} pengguna.",
    partnerSettingAdminInfoAlt:
      " You can add up to {0} user. To request additional user, email to support@kokatto.com or through Kokatto sales representative team",
    settingAdminEmailDesc: "Pastikan email anggota tim sudah terdaftar di {0}",
    settingAdminSearch: "Search name or email",
    settingAdminFilter: "Filter Role",
    settingAdminName: "Name",
    settingAdminEmail: "Email",
    settingAdminRole: "Role",
    settingAdminInboundRole: "CS",
    settingAdminStatus: "Status",
    settingAdminAction: "Action",
    settingAdminPassword: "Password",
    settingAdminPasswordConfirm: "Confirm Password",
    settingAdminSelectRole: "Select Customer Service Position",
    settingAdminSelectGroup: "Select Group",
    settingAdminPlaceholderSelectGroup:
      "Group is required if you have at least 1 group",
    settingAdminSelectRoleSupervisor: "Supervisor 1",
    settingAdminSelectRoleSupervisorDescription:
      "View all Inbox but cannot reply and assigned to Chat",
    settingAdminSelectRoleCS: "Customer Service 2",
    settingAdminSelectRoleCSDescription: "View and reply to assigned Chat",
    settingAdminSelectFeaturesAccess: "Select Access Role",
    settingAdminPlaceholderSelectRole: "Select role for your team member",
    settingAdminCsTag: "CS Tagging",
    settingAdminCsTagDescription:
      "Use tagging to categorize CS agent. Examples: Jakarta, Bali, etc.",
    settingAdminCsTagOptional: "Optional",
    settingAdminPlaceholderName: "Enter name of your team member",
    settingAdminPlaceholderEmail: "Enter email for login",
    settingAdminPlaceholderCSRole: "Select Position",
    settingAdminPlaceholderCSRoleDisable:
      "Only for users who have access to the Inbox page",
    settingAdminPlaceholderFeaturesAccess:
      "Select according to the access role that has been determined",
    settingAdminPlaceholderCsTag: "Enter tag and separate it with comma (,)",
    settingAdminSave: "Add",
    settingAdminSaveEdit: "Save",
    settingAdminEdit: "Edit User",
    settingAdminDeleteTitle: "Delete User Access?",
    settingAdminDeleteDescription: "Deleted users can no longer log in to {0}",
    settingAdminDeleteYes: "Yes, delete",
    settingAdminDeleteNo: "No",
    settingAdminAddSuccess: "Successfully added new user",
    settingAdminAddFailed: "Failed to add new user",
    settingAdminAddSuccessAlt1: "Successfully added new team member",
    settingAdminDeleteSuccess: "Delete user successfully",
    settingAdminDeleteFailed: "Failed to delete user",
    settingAdminDeleteFailedChatroomExist:
      "Failed to delete user. Please resolve the chat to delete user.",
    settingAdminAddMax:
      "Cannot add a new user because it has reached the maximum limit",
    settingAdminAddMaxAlt1:
      "Hanya bisa tambah hingga {0} pengguna. Silakan hapus salah satu.",
    settingAdminUpdateSuccess: "Update user success",
    settingAdminUpdateError: "Update user failed",
    settingAdminPasswordError:
      "Password minimal 8 digit alphanumeric and accept special characters",
    settingAdminRequiredForm: "This field is required",
    settingAdminValidEmail: "Please enter a valid email address",
    settingAdminPasswordMatch: "Password is not match",
    settingAdminRoleAdminInfo: "Can access all features in {0}",
    settingAdminRoleCSInfo:
      "Can access Transaction, Products, and Customer Service in {0}",
    settingAdminRoleCSInfoAlt: "CS can access Customer Service features at {0}",
    settingAdminEmailUsed: "Email already registered with {0}",
    settingAdminCSRoleMustEmpty: "Role do not have access to Inbox Page",
    settingAdminCSRoleMustNotEmpty:
      "Please select position for accessing Inbox Page",

    settingStore: "Store Setup",
    settingStoreTabStoreInfo: "Store Profile",
    settingStoreTabAccountBank: "Bank Account",
    settingStoreTabCourier: "Shipping & Delivery",
    settingStoreTabChannel: "Channel",
    settingStoreCourier: "Layanan Jasa Pengiriman",
    settingStoreSellerCourier: "Kurir Penjual",
    settingStoreContact: "Contacts",
    settingStoreName: "Store name",
    settingStoreNamePlaceHolder: "Enter store name",
    settingStoreAddress: "Address",
    settingStoreAddressPlaceHolder: "Enter store address",
    settingStoreCategory: "Industry",
    settingStoreCategoryPlaceHolder: "Select industry",
    settingStoreBusinessType: "Business type",
    settingStoreBusinessTypePlaceHolder: "Select business type",
    settingStoreBusinessMode: "Industry",
    settingStoreBusinessModeIndustrySelection: "Select maximum {0} categories",
    settingStoreService: "Layanan",
    settingStoreServicePlaceHolder: "Pilih Layanan",
    settingStoreSelectRule: "Pilih maksimal 3 bidang usaha",
    settingStoreCourierSellerName: "Nama kurir",
    settingStoreCourierSellerNamePlaceholder: "Isi nama kurir",
    settingStoreCourierSellerDeliveryFeeType: "Pilih jenis ongkos kirim",
    settingStoreCourierSellerDeliveryFee: "Biaya ongkos kirim",
    settingStoreCourierMaxPrice: "harga maksimal",
    settingStoreCourierSellerDeliveryFeeTotalProductRule:
      "Isi harga maksimal terlebih dahulu lalu isi biaya ongkirnya",
    settingStoreCourierSellerDeliveryFeeTotalProduct:
      "Biaya ongkos kirim diatas harga maksimal",
    settingStoreCourierSellerAddDeliveryFeeTotalProduct:
      "Tambah opsi biaya ongkir",
    settingStoreCourierSellerDeliveryFeePlaceholder:
      "Jika gratis, masukkan angka 0",
    settingStoreCourierSellerDeliveryEta: "Estimasi waktu pengiriman",
    settingStoreCourierSellerDeliveryEtaPlaceholder:
      "Isi estimasi waktu pengiriman",
    settingStoreCourierSellerWABA: "Not yet connected to WABA account",
    settingStoreSaveEdit: "Save",
    settingStoreChannelDesc:
      "Connect your marketplace, website and social media account with {0} to respond every customer inquiry here.",
    settingStoreRemove: "Remove",
    settingStoreConnect: "Hubungkan akun",
    settingStoreChangeToRestaurantConfirmationTitle:
      "Ganti ke dashboard restoran?",
    settingStoreChangeToRestaurantConfirmationDescription:
      "Kamu akan mengganti dashboard dengan fitur dan layanan khusus restoran. Pastikan layanan dan jadwal operasional sudah sesuai, karena akan memengaruhi pesanan.",
    settingStoreChangeToOnlineStoreConfirmationTitle:
      "Ganti ke dashboard toko online?",
    settingStoreChangeToOnlineStoreConfirmationDescription:
      "Kamu akan mengganti dashboard dengan fitur dan layanan khusus toko/jualan online.",
    settingStoreChannelLimitationInfoBanner:
      "Channel kamu bisa terhubung ke {0} akun. Perlu lebih banyak? Hubungi {1}",

    settingStoreModeConfirmationCancel: "No, cancel",
    settingStoreModeChangeConfirmationProceed: "Ya, ganti",
    settingStoreChannelMPChatTitle: "Connect to your sales channel chat",
    settingStoreChannelMPChatDecription:
      "You can connect more than one account to connect to {0}.",
    settingStoreChannelSocialMediaTitle: "Connect social media messenger",
    settingStoreChannelFooterDescription: "Your data is 100% secure with {0}",
    settingStoreChannelPopUpDialogCreateChannel: "Connect to your {0} account",
    settingStoreChannelButtonAddAccount: "Add account",
    settingStoreChannelRestrictionChip: "Only available for Pro and Enterprise",
    settingStoreDialogAddMpTitle: "Tokopedia Account",
    settingStoreDialogAddMpDescription:
      "Make sure your store status is Power Merchant, Power Merchant Pro, or Official Store.",
    settingStoreDialogAddMpInputName: "Store username/URL",
    settingStoreDialogAddMpInputLabel: "Input your store name or URL",
    settingStoreDialogAddMpButton: "Connect now",
    settingStoreStatusChannelConnected: "Terhubung",
    settingStoreStatusChannelRefresh: "Refresh to update latest status",
    settingStoreStatusChannelErrorMpChannel:
      "Failed to connect your account. {0}",
    settingStoreStatusChannelTokopediaAccess:
      'Give access to {0} via <a href="https://seller.tokopedia.com/settings/thirdparty" target="_blank" style="color:#3d3d3d">Tokopedia Seller</a> page or check email sent by Tokopedia.',
    settingStoreStatusChannelContactSupport: "Contact {0}",
    settingStoreDeleteChannelDialogTitle: "Delete account?",
    settingStoreDeleteChannelDialogDescription:
      "If account is deleted, you can not access the related chatroom",
    settingStoreDeleteChannelDialogConfirmButton: "Yes, delete",
    settingStoreDeleteChannelDialogCancelButton: "Back",
    settingStoreSuccessDeleteAccountChannel:
      "Successfully removed account {0}.",
    settingStoreStatusChannelAlreadyExist: `<div class="text-color-error"> Account has been connected to {0} or similar platform.</div>`,
    settingStoreDialogAddMpInputNumber: "{0} Number",
    settingStoreDialogAddMpInputDisplayName: "Display Name on {0}",
    settingStoreDialogAddMpInputPhoneNumberLabel: "Example: 081234567890",
    settingStoreDialogAddMpInputDisplayNameLabel:
      "Enter your store or business name for display name",
    settingStoreDialogAddMpNextButton: "Next",
    settingStoreDialogAddMpErrorEmptyStore:
      "Username or store link is required.",
    settingStoreChannelRestrictionChipConnectedMoreThanLimit:
      "Your account has reached maximum of {0} connected channels. You can remove one of the connected account or contact {1} to upgrade your limit.",
    settingStoreChannelSocialMediaDecription:
      "You can only connect one account from each platform.",
    settingStoreStatusChannelConnecting: "Connecting your account.",
    settingStoreDialogAddViberInputName: "Bot Token",
    settingStoreDialogAddViberInputLabel:
      "Input your bot token from your Viber account",
    settingStoreDialogAddTelegramInputName: "Bot Token",
    settingStoreDialogAddTelegramInputLabel:
      "Input your bot token from your Telegram account",
    settingStoreDialogAddMpErrorEmptyToken: "Bot token is required",
    settingStoreDialogAddMpGetTokenText: "How to get the Bot tokens?",
    settingStoreDialogAddLineInputChannelSecret: "Channel Secret",
    settingStoreDialogAddLineInputChannelSecretLabel: "Input Channel Secret",
    settingStoreDialogAddLineInputChannelToken: "Channel Access Token",
    settingStoreDialogAddLineInputChannelTokenLabel:
      "Input Channel Access Token",
    settingStoreDialogAddMpGetChannelSecret: "How to get the tokens?",
    settingStoreDialogAddLineInputChannelName: "Channel Name",
    settingStoreDialogAddLineInputChannelNameLabel: "Input Channel Name",

    settingStoreQRWhatsappDialogTitle: "Scan QR Code",
    settingStoreQRWhatsappDialogExpiredCountdown:
      "QR Code will be expired in {0}",
    settingStoreQRWhatsappDialogAlreadyExpired: "QR Code is expired",
    settingStoreQRWhatsappDialogBannerInfo:
      'Make sure to scan QR with WhatsApp account <span class="text-body-16--bold">{0}</span> ',
    settingStoreQRWhatsappDialogInstructionFirst:
      "1. Open WhatsApp application on your phone.",
    settingStoreQRWhatsappDialogInstructionSecond: `<div class="text-with-icons">2. Tap <span class="text-body-16--bold">Menu</span> <div class="icon-three-dots"></div> or <span class="text-body-16--bold">Settings</span> <div class="icon-setting"></div> then tap <span class="text-body-16--bold">Linked Devices.</span><div>`,
    settingStoreQRWhatsappDialogInstructionThird:
      '3. Tap <span class="text-body-16--bold">Link a Device.</span>',
    settingStoreQRWhatsappDialogInstructionFourth:
      "4. Direct your phone to the screen to scan QR Code above.",
    settingStoreQRWhatsappDialogInstructionFifth:
      "5. Wait until this QR info automatically close after connected successfully.",
    settingStoreQRWhatsappDialogRegenerateQR: "Generate new QR Code",
    settingStoreQRWhatsappDialogErrorShowingQR:
      "Failed showing QR Code. Pleasr try to reopen this page.",
    settingStoreQRWhatsappDialogErrorExceedLimit:
      "Exceeded the QR Code generation limit. Please try closing and opening this page again.",
    settingStoreQRWhatsappDialogErrorScanWrongNumber:
      "The WhatsApp account used for scanning is different from the registered number. Please generate a new QR Code and scan it using the appropriate account.",
    settingStoreSuccessConnectChannelNotif:
      'Success connecting <span class="text-body-16--bold">{0}<span>',
    settingStoreConfirmDialogCloseQRDialogTitle: "Cancel connecting Whatsapp?",
    settingStoreConfirmDialogCloseQRDialogDescription:
      "You need to repeat the Whatsapp connecting process from the beginning if you close this QR info.",
    settingStoreConfirmDialogCloseQRDialogCancelButton: "Continue connecting",
    settingStoreConfirmDialogCloseQRDialogConfirmButton: "Yes, cancel",
    settingStoreFailedDeleteChannel:
      "Failed to remove account. Please try again.",
    settingStoreUpgradePackagePopUpDialogTitle: "Upgrade to Pro Package",
    settingStoreUpgradePackagePopUpDialogDescription:
      'Channel WhatsApp is only available on Pro package. You can upgrade subscription package starts from <span class="text-body-16--bold">71K/month.</span>',
    settingStoreUpgradePackagePopUpDialogCancelButton: "Later",
    settingStoreUpgradePackagePopUpDialogConfirmButton: "Upgrade package",
    settingStoreConnectShopeeDialogDataSafetyGuarantee:
      "Your data is securely protected.",
    settingStoreConnectShopeeDialogInstructionFirst:
      'Click button <span class="text-body-14--bold">Connect.</span> Next, you will be directed to Shopee page.',
    settingStoreConnectShopeeDialogInstructionSecond:
      '<span class="text-body-14--bold">Login to Your Shopee Account.</span> Make sure your account is not connected to other {0} account.',
    settingStoreConnectShopeeDialogInstructionThird: `Click <span class="text-body-14--bold">Confirm Authorization.</span> After successfully connected, you can reply customer's chat in Shopee through {0}.</span>`,
    settingStoreFailedConnectChannel: "Something went wrong. Please try again.",
    settingStoreConnectViberDialogInstructionDescription:
      'To connect a Viber account you will need a Bot Token, <span class="text-body-14--bold"> make sure you already have a Bot account.</span>',
    settingStoreConnectViberDialogInstructionTitle:
      "How to create a Bot account and get Bot Tokens:",
    settingStoreConnectViberDialogInstructionFirst:
      'Login to your Viber account and go to <a href="https://partners.viber.com/account/create-bot-account" target="_blank" style="color:#3d3d3d">Create bot account page.</span>',
    settingStoreConnectViberDialogInstructionSecond:
      '<span class="text-body-14--bold">Input your business information</span> and click <span class="text-body-14--bold">Create</span> to create a Bot account.',
    settingStoreConnectViberDialogInstructionThird:
      'After successfully creating the account, click <span class="text-body-14--bold">Info on the Bot account to be used and copy the Token</span> to be entered on the {0} page.',
    settingStoreConnectTelegramDialogInstructionDescription:
      'To connect a Telegram account you will need a Bot Token, <span class="text-body-14--bold"> make sure you already have a Bot account.</span>',
    settingStoreConnectTelegramDialogInstructionTitle:
      "How to create a Bot account and get Bot Tokens:",
    settingStoreConnectTelegramDialogInstructionFirst:
      'Login to your Telegram account and go to <a href="https://t.me/botfather" target="_blank" style="color:#3d3d3d">chat BotFather.</span>',
    settingStoreConnectTelegramDialogInstructionSecond:
      'Send <span class="text-body-14--bold">/newbot</span> to create new bot or <span class="text-body-14--bold">/token</span> to directly get token',
    settingStoreConnectTelegramDialogInstructionThird:
      'After successfully creating the bot, <span class="text-body-14--bold">copy Token</span> to be entered in the {0} page',
    settingStoreFailedConnectChannelInvalidToken: "The token is invalid.",
    settingStoreFailedConnectChannelDuplicateOnOtherClient:
      "Your token is a duplication. Try another token.",
    settingStoreVerificationLineChannelStatus:
      "Lakukan verifikasi Webhook URL via halaman LINE Console. Salin Webhook URL {0}.",
    settingStoreVerificationLineChannelStatusOpenDialog: "disini",
    settingStoreConnectLineDialogInstructionDescription:
      'To connect a LINE account you will need a Channel Access Token and Channel Secret, <span class="text-body-14--bold">make sure you already have a LINE Official Account.</span>',
    settingStoreConnectLineDialogInstructionTitle:
      "How to create an Official Account and get Tokens:",
    settingStoreConnectLineDialogInstructionFirst:
      "Login to your LINE Developers account or create new account on {0} , create provider and channel by following {1}.",
    settingStoreConnectLineDialogInstructionFirstHowToLink: "these steps",
    settingStoreConnectLineDialogInstructionSecond:
      'Get <span class="text-body-14--bold">Channel Secret:</span> LINE Developer Console > Basic Settings > Channel Secret',
    settingStoreConnectLineDialogInstructionThird:
      'Get <span class="text-body-14--bold">Channel Access Token:</span> LINE Developer Console > Messaging API > Channel access token > click Issue to generate the token',
    settingStoreActivateWebhookLineDialogTitle:
      "Input and Activate {0} Webhook on LINE Developers",
    settingStoreActivateWebhookLineDialogDescription:
      "To continue, copy and input the following Webhook URL in LINE Developers Webhook Settings page. Make sure to Verify and Activate the webhook to connect chats with {0}.",

    tooltipSuccessCopyText: "Berhasil disalin",

    settingStoreGiveAccessTokopediaDialogFirstTitle:
      "Give access to {0} on Tokopedia Seller",
    settingStoreGiveAccessTokopediaDialogFirstDescription:
      'To continue, please give access via <a href="https://seller.tokopedia.com/settings/thirdparty" target="_blank" style="color:#3d3d3d">Tokopedia Seller</a> on Third Party Setting or check email sent by Tokopedia.',
    settingStoreGiveAccessTokopediaDialogFirstButton: "Next",
    settingStoreGiveAccessTokopediaDialogSecondTitle: "Give Reply Chat access",
    settingStoreGiveAccessTokopediaDialogSecondDescription:
      "Next, you need to checklist option Reply Chat on Chat setting so you can reply chat using {0} that has been connected.",
    settingStoreGiveAccessTokopediaDialogSecondButton: "Done",
    settingStoreSuccessConnectMPChannelNotif:
      'You’ve successfully connected to <span class="text-body-16--bold">{0}<span>',
    settingStoreQRWhatsappDialogPreparingServerTitle:
      "Please wait a moment, we are preparing QR Code to connect your Whatsapp Account",
    settingStoreQRWhatsappDialogPreparingServerSubtitle:
      "QR Code will be shown in 2 minutes.",
    settingStoreQRWhatsappDialogPreparingServerContactCS:
      "QR Code is not showing? Contact {0}",
    settingStoreDialogAddMpWhatsappInfo:
      "You can only connect to 1 WhatsApp number.",
    surveyRatingTemplateDescription: "Template Survey",
    surveyRatingRemarksDescription: "Remarks",
    surveyRatingCSTagDescription: "CS Tag",
    loginBannerTitle: "Manage chats & transactions on 1 dashboard",
    loginBannerSubTitle:
      "Facebook's official business partner integrated with WhatsApp Business API",
    loginFormTitle: "Login",
    loginFormInputEmail: "Email",
    loginFormInputEmailMessageRequired: "Email is required",
    loginFormInputEmailMessageError: "Incorrect email format",
    loginFormInputPassword: "Password",
    loginFormInputPasswordMessageRequired: "Password is required",
    loginFormInputPasswordMessageError: "Email or password does not match",
    resetPhoneInputFormPasswordMessageError:
      "Password should have 8 digit alphanumeric and have special characters.",
    loginFormSubmit: "Submit",
    loginFormSubmitError: "Email or password is invalid",
    loginFormSubmitErrorAlt:
      "Email or password is invalid. Make sure your email registered on {0}",
    loginFormForgotPassword: "Forgot Password?",
    loginFormTermHtml:
      "By logging in, you agree to the KOALA+ terms & conditions.",
    loginFormRegisterLabel: "Create New Account",
    loginFormRegisterAction: "Register",
    loginFormRegisterHtml: "Don't have an account yet? Sign up",

    resetPhoneInputFormTitle: "Forgot the password?",
    resetPhoneInputFormSubtitle:
      "Enter the mobile number connected<br>to KOALA+ and we will send the OTP code.",
    resetPhoneInputFormPhoneLabel: "Mobile phone number",
    resetPhoneInputFormPhoneRequired: "Mobile phone number is required",
    resetPhoneInputFormPhoneError: "Invalid mobile number format",
    resetPhoneInputFormPhoneUnregister:
      "Phone number is not register at KOALA+",
    resetPhoneInputFormSubmit: "Continue",
    resetPhoneInputFormOTPTitle: "Enter OTP code",
    resetPhoneInputFormOTPSubtitle:
      "Write the 5-digit secret code that we send via<br>WhatsApp to the number:",
    resetPhoneInputFormOTPTimerTitle: "Haven't received the code yet? ",
    resetPhoneInputFormOTPTimerCount: "Wait {0} seconds",
    resetPhoneInputFormOTPTimerFinish: "Resend",
    resetPhoneInputFormPasswordTitle: "Enter new password",
    resetPhoneInputFormPasswordSubtitle:
      "Password must be 8 alphanumeric digits and<br>can be special characters.",
    resetPhoneInputFormPasswordSubmit: "Change Password",
    resetPhoneInputFormPasswordNewLabel: "Password",
    resetPhoneInputFormPasswordConfirmLabel: "Confirm Password",
    resetPhoneInputFormSuccessTitle: "Successfully changed password",
    resetPhoneInputFormSuccessSubtitle:
      "Soon you will be directed to the login page.",
    resetPhoneInputFormPasswordNewMessageRequired: "Password is required",
    resetPhoneInputFormPasswordConfirmMessageRequired:
      "Confirm Password is required",
    resetPhoneInputFormPasswordConfirmMessageError:
      "Confirm Password should equal with the password",

    signUpStepperAccount: "Informasi Akun",
    signUpStepperStore: "Informasi Toko",
    signUpStepperWaba: "Hubungkan WABA",
    signUpStepperSubscription: "Langganan Premium",
    signUpAccountInputFormTittle: "Buat Akun Baru",
    signUpAccountInputFormPhoneLabel: "Nomor HP",
    signUpAccountInputFormPhoneRequired: "Nomor HP wajib diisi",
    signUpAccountInputFormPhoneInfo:
      "Pastikan no. HP bukan nomor yang terdaftar di akun WABA (WhatsApp Business API)",
    signUpAccountInputFormEmailLabel: "Email",
    signUpAccountInputFormEmailRequired: "Email wajib diisi",
    signUpAccountInputFormEmailInvalid: "Email tidak valid",
    signUpAccountInputFormPasswordLabel: "Password",
    signUpAccountInputFormPasswordRequired: "Password wajib diisi",
    signUpAccountInputFormPasswordInfo:
      "Password harus minimal 8 digit alfanumerik dan boleh karakter spesial.",
    signUpAccountInputFormPasswordConfirmLabel: "Konfirmasi Password",
    signUpAccountInputFormPasswordConfirmRequired:
      "Konfirmasi Password wajib diisi",
    signUpAccountInputFormPasswordConfirmHasEqual:
      "Konfirmasi Password harus sama",
    signUpAccountInputFormReferralLabel: "Referral Code",
    signUpAccountInputFormTermHtml:
      'Dengan masuk, kamu menyetujui<br> <a style="color:#8D8D8D; text-decoration:underline;">syarat & ketentuan</a> KOALA+.',
    signUpAccountInputFormLoginLabel: "Sudah punya akun? ",
    signUpAccountInputFormLoginAction: "Login",
    signUpAccountInputFormSubmit: "Daftar",
    signUpStoreInputFormTittle: "Isi detail tokomu",
    signUpStoreInputFormNameLabel: "Nama Toko",
    signUpStoreInputFormAddressLabel: "Alamat Toko",
    signUpStoreInputFormBFieldLabel: "Bidang Usaha",
    signUpStoreInputFormBTypeLabel: "Tipe Bisnis",
    signUpStoreInputSubmit: "Lanjut",
    signUpStoreInputSkip: "Lewati",
    signUpConfirmNewTitle: "Daftar Nomor Baru",
    signUpConfirmNewContent:
      "Dengan daftar KOALA+, maka otomatis Anda telah terdaftar di aplikasi KOALA yang bisa Anda download di Playstore. Semua data akan saling terhubung.",
    signUpConfirmExistTitle: "Nomor HP sudah terdaftar",
    signUpConfirmExistContent:
      "Anda sudah memiliki akun di KOALA App.<br>" +
      "Semua data di akun KOALA App Anda saling terhubung dengan KOALA+.",
    signUpConfirmCancel: "Ubah",
    signUpConfirmSubmit: "Ya, lanjutkan",
    signUpWabaInputFormTitleHtml: "Hubungkan Akun<br>WhatsApp Business API",
    signUpSubscriptionTitle: "Pilih paket berlangganan KOALA+",
    signUpSubscriptionNotes:
      "*Tambah agen Customer Service Live dengan Rp199.000/agen/bulan",
    signUpSuccessTitle: "Berhasil buat akun!",
    signUpSuccessSubtitle: "Berhasil buat akun!",
    dashboardGreeting: "Hi, ",
    dashboardSetupAccount: "Let’s Set Up Your {0} Account!",
    dashboardSetupStep: "steps completed",
    dashboardUpgradeTitle: "Upgrade to premium",
    dashboardUpgradeTitleAlt: "Connect WABA",
    dashboardUpgradeDescription:
      "Set up a WhatsApp Business account and subscribe to KOALA+ premium to enjoy maximum sales support features.",
    dashboardKoalaRestoGreetingTitle: "Selamat datang di KOALA Resto!",
    dashboardUpgradeDescriptionAlt:
      "Set up a WhatsApp Business API (WABA) account to get all features.",
    dashboardKoalaRestoGreetingDescription:
      "Tingkatkan penjualan dengan fitur yang dirancang khusus bagi restoran.",
    dashboardBonusFreeTitle: "Bonus chat credits up to a total of 300,000!",
    dashboardBonusFreeDesc:
      "Your premium KOALA+ subscription and chat credits will be topped up to a total of 300 thousand every month.",
    dashboardBonusPremiumTitle: "Bonus Chat Credits up to a total of 350,000!",
    dashboardBonusPremiumDesc:
      "With a premium KOALA+ subscription, your chat credits will be top up to a total of 350,000 every month.",
    dashboardBonusFreeTrialTitle:
      "Congratulations! You have access to a free trial of KOALA+ premium for 30 days!",
    dashboardBonusFreeTrialDesc:
      "Now you can use all the features of KOALA+. The free trial period will end on",
    dashboardSubsAlert1: "Your subscription expires at ",
    dashboardSubsAlert2:
      "Let's renew your subscription so you can continue to use the KOALA+ feature.",
    dashboardSubsRenew: "Renew Subscription",
    dashboardTrxSumTitle: "Transaction Summary",
    dashboardProdListTitle: "Product List",
    dashboardTrxUnpaid: "Pesanan Baru",
    dashboardTrxProcessed: "Disiapkan",
    dashboardTrxSent: "Siap",
    dashboardProdSale: "Products for Sale",
    dashboardProdNotSale: "Not For Sale",
    dashboardFeatureCheck: "Try it",
    dashboardFeatureTitle: "Chat with your Customers",
    dashboardFeatureBroadcast: "Broadcast Message",
    dashboardFeatureBroadcastDetail:
      "Send a message to multiple contacts easily.",
    dashboardFeatureChatbot: "Chatbot",
    dashboardFeatureChatbotDetail:
      "Activate chatbot to reply messages quickly.",
    dashboardFeatureCS: "Customer Service",
    dashboardFeatureCSDetail:
      "Set up your own customer service to respond to every incoming message directly on the dashboard.",
    dashboardViewMore: "See More",
    dashboardHide: "See less",
    dashboardSetWABA: "Connect your WABA account",
    dashboardSetWABAAction: "Connect",
    dashboardSubs: "Subscribe to KOALA+ premium",
    dashboardSubsAction: "Subscribe",
    dashboardStartSellTitle: "Start Selling",
    dashboardStartSellDescription:
      "Fill in store information, add products, and verify bank accounts to start selling",
    dashboardFillStoreInfo: "Fill in store information",
    dashboardFillStoreInfoAction: "Fill now",
    dashboardAddProduct: "Add product",
    dashboardAddProductAction: "Add",
    dashboardVerifBankAccount: "Account verification",
    dashboardVerifBankAccountAction: "Verify",
    dashboardChatCustomerTitle: "Chat Your Customer",
    dashboardChatCustomerDescription:
      "Top up chat credits to use WhatsApp Business. You can also use other features to communicate with your customers and promote your business.",
    dashboardTopUpChatCredit: "Top Up Chat Credits",
    dashboardTopUpChatCreditAction: "Top Up",
    dashboardSendBroadcast: "Send broadcast message",
    dashboardSendBroadcastAction: "Start sending",
    dashboardSetCS: "Set up CS Agent chat",
    dashboardSetCSAction: "Set up now",
    dashboardActivateChatbot: "Activate Chatbot",
    dashboardActivateChatbotAction: "Activate",
    dashboardWABAReject0:
      "Your application has been rejected by Facebook. Please reset your WABA account. Please note the following:",
    dashboardWABAReject1:
      "Make sure your display name complies with the Facebook Terms and Conditions.",
    dashboardWABAReject2:
      "Make sure FB Business Manager is verified according to",
    dashboardWABARejectFBTerms: "Facebook Terms and Conditions",
    dashboardWABAReject3:
      "If you encounter other problems when connecting your WABA account, please contact us via email to",
    dashboardCheckDetail: "View Details",
    dashboardNewFeatureTitle:
      "Manage & add contact information for customer data",
    dashboardNewFeatureDescription:
      "Now you can add your contact information field to fit your needs.",
    dashboardNewFeatureButton: "Try Now",

    transactionTitle: "Transaction",
    transactionUnpaid: "Pesanan Baru ",
    transactionProcessing: "Disiapkan ",
    transactionSent: "Siap ",
    transactionDone: "Selesai ",
    transactionCancel: "Batal ",
    transactionEmptyTitle: "No transactions yet",
    transactionEmptyInfo:
      "All transactions will appear here. Make transactions through the KOALA application.",
    transactionNotes: "Notes",

    additionalVariant: "Additional variant",

    transactionOutOfStock: "Out of stock",
    transactionMakeAnother: "Make another",

    transactionDeleteProductVariantTitle: "Delete this product and variant?",
    transactionDeleteProductVariantDesc:
      "You will delete this product and variant, this item will not include in the order",
    transactionDeleteProductVariantToastSuccess:
      "Success delete product and variant.",

    transactionAddProductManualSuccess: "Success add product.",
    transactionAddProductManual: "Adding product",
    transactionAddProductManualDisclaimer:
      "This action will not saved in catalog.",
    transactionAddProductManualDetail: "Product detail",

    moveChatConfirmationDialogTitle: "Move to another menu?",
    moveChatConfirmationDialogDescription:
      "If you move to another menu, transaction data will not be saved",
    moveChatConfirmationDialogContinueButton: "Continue create transaction",
    moveChatConfirmationDialogMoveTo: "Move to another menu",

    forwardChatConfirmationDialogTitle:
      "Teruskan chat dan hapus data transaksi?",
    forwardChatConfirmationDialogDescription:
      "Jika teruskan chat, data transaksi yg kamu masukkan akan terhapus",
    forwardChatConfirmationDialogContinueButton: "Tidak, kembali",
    forwardChatConfirmationDialogMoveTo: "Ya, teruskan chat",

    resolveChatConfirmationDialogTitle:
      "Selesaikan chat dan hapus data transaksi?",
    resolveChatConfirmationDialogDescription:
      "Jika selesaikan chat, data transaksi yg kamu masukkan akan terhapus",
    resolveChatConfirmationDialogContinueButton: "Tidak, kembali",
    resolveChatConfirmationDialogMoveTo: "Ya, selesaikan chat",
    chatbotCustomComponentTitle: "Custom Auto Reply Chatbot",
    chatbotCustomComponentDescription:
      "Currently you are using the {0} package custom chatbot and the {1} auto reply fee is 275.48 chat credits/conversation within 24 hours. To make changes, send an email to",

    templateListDescription:
      "Add and set up templates that can be sent to customers here.",
    tabTitleBroadcastAndNewMessage: "Broadcast & New Message",
    tabTitleTemplate24H: "Follow Up Chat",
    tabTitleBroadcastMessage: "Broadcast Template",
    labelNew: "New",
    broadcastInformation:
      "The following is a list of broadcast message templates or new messages.",
    templateInformation:
      "The conversation will be ended (expired) after 24 hours from the last WhatsApp message. If you want to continue the conversation, you can send a follow up template message at a cost of 511.5 chat credits/contact (11% VAT included).",

    templateDeleteDialogConfirm: "Yes, delete",

    followUpPageTitle: "Template Follow Up Chat",
    followupHeaderName: "Template Name",
    followupHeaderContent: "Template Content",
    followUpHeaderNameDescription:
      "Give the template name to make it easier for you to choose a template. The name will not appear in broadcast messages.",

    createFollowUpSubmitDisclaimerTitle: "Continue adding template?",
    createFollowUpSubmitDisclaimerContent:
      "The template will go through an evaluation and verification process by Meta max. within 1x24 hours.",
    createFollowUpSubmitDisclaimerCancelButton: "Cancel",
    chatroomContactDetailTitle: "Chat detail",
    chatroomContactDetailEditTitle: "Edit Contact",
    chatroomPickPriority: "Chat priority",
    chatroomChatDetail: "Chat Detail",

    chatroomMediaNoPreview: "No preview available",
    chatroomMediaCaption: "Ketik isi keterangan disini..",
    chatroomMediaDropInstruction: "Drag and drop your file here.",
    chatroomPickCS: "Select CS Agent",
    chatroomSearchCSNotFoundTitle: "Agent not found",
    chatroomSearchCSNotFoundSubtitle: "You can try again using other keywords",
    chatroomCSTotalOpenTicket: "{0} Chats",

    chatroomErrorMessageFileWrongFormat:
      "Image format is not supported. Please use jpeg or png and 8bit or RGB.",
    chatroomErrorMessageFileTooLarge: "Attachment size is larger than {0}.",
    chatroomErrorMessageFileNotSupported:
      "Can not display the message content.",
    chatroomErrorMessageContentNotSupported: "Unable to display message.",
    chatroomErrorMessageVideoDuration:
      "Video duration is longer than {0} seconds",
    chatroomErrorMessageVideoFormat:
      "Video format is not supported Please use MP4 or H264 format",
    chatroomErrorMessageImageFormat:
      "Image format is not supported Please use jpeg, png or gif format",
    chatroomErrorMessageFileName: "File name is longer than {0} characters.",

    chatroomAssignedEvent: "{1} has been assigned to this conversation by {0}",
    chatroomClosedEvent: "Ticket has been resolved by {0}",

    switcherNewMessageOnText: "Deactive this template as new message",
    switcherNewMessageOffText:
      "Activate this template to be used as new message",

    tooltipNewMessage: "Send new message",
    tooltipAttachmentButtonChannelSupport:
      "Fitur kirim tautan tidak tersedia di channel {0}",

    tooltipAddChannelButton: "Hanya owner/admin yang dapat menambahkan channel",

    formNewMessageTitle: "Send New Message",
    formNewMessageInformation:
      "The cost for sending a new message depends on the price of the template category.",
    formNewMessagePhoneNumberPlaceHolder: "Customer's Phone Number",
    formNewMessagePhoneNumberHint:
      "Input customer's phone number manually, ex: 628xxx or 08xxx.",
    formNewMessageSelectTemplatePlaceHolder: "Select new template",
    formNewMessageFooter: "Please contact admin to add other new templates.",
    formNewMessageCancel: "Cancel",
    formNewMessageSubmit: "Send message",

    formNewMessageNoTemplateHeader: "No template to use yet",
    formNewMessageNoTemplateContent:
      'Please contact admin to add new templates. If you have any problem related with this, please send a message to KOALA Customer Care via WhatsApp <a style="color:#1843A1; text-decoration:none;" href="https://wa.me/6287752670270" target="_blank">(087752670270)</a>.',

    formatNewMessagePhoneNumberInvalid: "Phone number is invalid",
    formatNewMessagePhoneNumberIsEmpty: "Phone number is required",
    formatNewMessageSelectedTemplateIsEmpty:
      "New message template is required to be selected",
    formatNewMessageSelectedTemplateLength:
      "Phone number consists of 9-13 characters",
    formatNewMessageNumberIsActive:
      "Can't send a new message because the phone number is still exist on the active chat list. Please finish the chat first.",

    chatRoomCantSendMessage:
      "You have successfully sent a new message, but you have not been able to reply to this message. Wait for the customer to reply.",
    chatRoomExpiredChatHeader: "This chat has expired",
    chatRoomExpiredChatAttachmentInfo:
      "Unable to download attachment if chat is expired",
    chatRoomExpiredChatContentWhatsApp:
      "If needed, you can still send follow up chat template. The chat will remain expired until you receive a reply from customer.",
    chatRoomExpiredChatContentFacebook:
      "You can't reply the chat from Facebook because it has been more than 24 hours since the last reply from the customer. Please resolve this chat.",
    chatRoomExpiredChatContentInstagram:
      "You can't reply the chat from Instagram because it has been more than 24 hours since the last reply from the customer. Please resolve this chat.",
    chatRoomExpiredChatContentTelegram:
      "You can't reply the chat from Telegram because it has been more than 24 hours since the last reply from the customer. Please resolve this chat.",
    chatRoomExpiredChatButton: "Send Follow Up Chat",
    chatRoomTextAreaMaximumCharacterError:
      "It has already reached the {0} character limit",

    formFollowUpTitle: "Send Follow Up Chat 24 Hours",
    formFollowUpInformation:
      "\u2022 The chat will remain expired until the customer replies.<br>" +
      "\u2022 The cost for sending a new message depends on the price of the template category.",
    formFollowUpSelectTemplatePlaceHolder: "Select follow up chat template",
    formFollowUpFooter: "Please contact admin to add other templates.",

    formFollowUpNoTemplateHeader: "No template to use yet",
    formFollowUpNoTemplateContent:
      'Please contact admin to add new templates. If you need assistance, send a message via WhatsApp to KOALA Customer Care <a style="color:#1843A1; text-decoration:none;" href="https://wa.me/6287752670270" target="_blank">(087752670270)</a>.',

    followUpSelectedTemplateIsEmpty:
      "You have to choose a follow up chat template",

    chatCreditIsEmptyHeader: "Chat credits are not enough",
    chatCreditIsEmptyNewMessageContent:
      "You cannot send a new message because there are not enough chat credits.<br><br>" +
      "Please contact the admin to top up chat credits so you can send a new message.",
    chatCreditIsEmptyFollowUpChatContent:
      "You cannot send a 24 hour follow up chat because there are not enough chat credits.<br><br>" +
      "Please contact the admin to top up chat credits so you can send a 24 hour follow-up chat.",

    smartChatSettingStoreGoToSmartSeller:
      "Data change can be done in SmartSeller",

    subscriptionFixedBannerFreeTrialPeriod:
      "Free trial will be end in {0} days.",
    subscriptionFixedBannerFreeTrialPeriodEndsToday:
      "Free trial will end today.",
    subscriptionFixedBannerPackagePeriod:
      "Your subscription package will end in {0} days.",
    subscriptionFixedBannerPackageEndsToday:
      "Your subscription package will end today",
    subscriptionFixedBannerUpgradeCTAOwner: "Let's upgrade your account now!",
    subscriptionFixedBannerUpgradeCTACS:
      "Contact owner to upgrade subscription.",
    subscriptionFixedBannerRenewCTAOwner: "Renew subscription now.",
    subscriptionFixedBannerRenewCTACS:
      "Remind owner to renew your subscription.",

    forgotPasswordConfirmationTitle: "Forgot the password?",
    forgotPasswordConfirmationInfo:
      "You will be directed to Smart Seller to change your password",
    forgotPasswordConfirmationButtonCancel: "Back",
    forgotPasswordConfirmationButtonConfirm: "Yes, continues",
    infoAllPaymentConfigurationDashboardTitle:
      "Online Payment is temporarily off",
    infoAllPaymentConfigurationDashboardDescription:
      "Transactions using online payment are not available at the moment.",
    infoPaymentConfigurationDashboardTitle: "Payment {0} is temporarily off",
    infoPaymentConfigurationDashboardDescription:
      "Transactions using payment {0} are not available at the moment.",
    infoPaymentConfigurationChatCreditTitle:
      "Payment Method is temporarily off",
    infoPaymentConfigurationChatCreditDescription:
      "Sorry, top up is not available at the moment.",
    infoPaymentConfigurationWalletTitle: "Payment Method is temporarily off",
    infoPaymentConfigurationWalletDescription:
      "Sorry, withdrawals(auto & manual) are not available at the moment.",
    downloadHistoryChatDialogTitle: "Download chat history",
    downloadHistoryChatDialogFrom: "From",
    downloadHistoryChatDialogUntil: "To (max. 31 days)",
    downloadHistoryChatDialogXls: "The download file format is .xls",
    downloadHistoryChatDialogButton: "Download",
    downloadHistoryChatErrorDownload:
      "Download failed, please check your internet network again",
    resolvedDuplicateChatConfirmationDialogTitle: "Resolved chat confirmation",
    resolveDuplicateChatConfirmationDialogDescription:
      "We detect more than 1 active chat related to this number. Are you sure want to resolve all active chat related to this customer number?",
    resolveDuplicateChatConfirmationDialogCancelButton: "Back",
    resolveDuplicateChatConfirmationDialogConfirmButton: "Yes, continue",

    chatMultipleStoreLabel: "All channel",

    chatRoomPermissionAccessChatHeader:
      "Beri izin akses balas chat pihak ketiga",
    chatRoomPermissionAccessChatContent: `Agar bisa membalas chat ini, kamu perlu beri izin akses {0} untuk balas chat via halaman <a href="https://seller.tokopedia.com/settings/thirdparty" target="_blank">Tokopedia Seller</a> atau via email yang dikirim oleh pihak Tokopedia.`,

    koalaPlusLoginIllustrationHeaderText:
      "Kelola chat & transaksi di 1 dashboard",
    koalaPlusLoginIllustrationSubtitleText:
      "Partner bisnis resmi Facebook yang terintegrasi dengan WhatsApp Business API",

    emptyString: "",
    seeProductDetail: "See product detail",
    seeTransactionDetail: "See transaction detail",
    productInformation: "Product Information",
    customerCreatesTransaction: "Customer creates transaction",

    chatroomMPChatInfo:
      "{0} only show the link that you send in {0} or when channel connected.",

    loginFormEmailNotRegisteredPartner:
      "Oops, the email is not registered. Please ask the owner if the email is registered to {0}.",

    labelChatCreditsTopUpSelectPayment: "Payment",
    labelChatCreditsTopUpSelectPaymentSummary: "Summary",
    labelChatCreditsTopUpGuide: "fill amount with number",

    labelSubscriptionPaymentSelection: "Select Payment Method",
    labelSubscriptionPackageSelection: "Select Subscription package",
    labelSubscriptionPackageSelectionUpgrade: "Upgrade Price",
    labelSubscriptionCalculationSummary: "Summary",
    labelSubscriptionPaymentBaseAmount: "Total Subscription Price",
    labelSubscriptionExtendDuration:
      "Increase subscription duration at the same time",
    labelSubscriptionPackageEndUntil: "package active until",
    labelServiceFee: "Service Fee",
    labelServiceFeeDescription:
      "Administrative fees charged by KOALA+ for users",
    labelTaxDescription: "{0} of the total subscription price + service fee",
    labelSubscriptionCalculationTotal: "Total Payment",
    subscriptionPaymentEmptySelectionMessage: "Must choose a payment method",
    paymentSelectionFeeAmountDescription: "Service fee is charged ",
    subscriptionPaymentPreTncDesc: "By continuing to pay, you agree to",
    subscriptionPaymentPostTncDesc: "that applied",
    subscriptionPaymentTncDesc: "term and conditions",
    subscriptionTncTitle: "{0} Subscripiton Terms and Conditions",
    subscriptionTncContentsmart_seller: `<ol>
    <li>You can subscribe to the SmartChat package (Starter and Pro) while the account is still in Free or Free Trial status with a choice of 3/6/12 month periods.</li>
    <li>The length of 1 month in a subscription is 30 days.</li>
    <li>You cannot cancel or refund an active SmartChat subscription package.</li>
    <li>You cannot change from the Pro package to the Starter package (downgrade) if you are still actively subscribed to Pro.</li>
    <li>You can change to the Pro package (upgrade) at a cost of IDR 71,000 per month (30 days). The month calculation will be rounded up for the upgrade cost calculation.</li>
    <li>Upgrading the package will not extend the validity period of the package. To increase the validity period, you need to renew the subscription and will be charged for the selected package.</li>
  </ol>`,
    subscriptionTncContentkoala_plus: `<ol>
    <li>You can subscribe to the Koala+ package (Starter and Pro) while the account is still in Free or Free Trial status with a choice of 3/6/12 month periods.</li>
    <li>The length of 1 month in a subscription is 30 days.</li>
    <li>You cannot cancel or refund an active Koala+ subscription package.</li>
    <li>You cannot change from the Pro package to the Starter package (downgrade) if you are still actively subscribed to Pro.</li>
    <li>You can change to the Pro package (upgrade) at a cost of IDR 300,000 per month (30 days). The month calculation will be rounded up for the upgrade cost calculation.</li>
    <li>Upgrading the package will not extend the validity period of the package. To increase the validity period, you need to renew the subscription and will be charged for the selected package.</li>
  </ol>`,
    infoBannerTitle:
      "Category and sub category addition for broadcast template",
    infoBannerSubTitle:
      "From now on, templates will be divided into categories and subcategories based on message content",
    categoryMessageTitle: "Message Category",
    categoryMessageSubTitle:
      "Select a category that reflects the message you’re sending. The selected category will determine the charged price.",
    categoryMessageLearnMore: "Learn more",
    categoryMessageTnC:
      "If the content of the template does not match the selected category, then there will be an adjustment to determine the appropriate category by Meta.",
    authentication: "Authentication",
    authenticationDetail:
      "Message informing code for verification or login. For creating template with this category, please contact our",
    verificationCode: "Verification Code (OTP)",
    recoveryAccount: "Account Recovery Code",
    utility: "Account & Transaction",
    utilityDetail:
      "Message related to transaction process, order status, and account info updates.",
    infoOrderStatus: "Order Status",
    infoPayment: "Billing",
    notificationReminder: "Reminder Notification",
    updateAccountInfo: "Account Updates",
    marketing: "Marketing",
    marketingDetail:
      "Message about products, services, and other information that is not related to transaction or verification.",
    greeting: "Greeting",
    promotion: "Promotion",
    infoAndRecomenndation: "Info & Recommendation",
    catalog: "Catalog",
    categoryTnC:
      "If the message content does not match the selected category, Meta will adjust the message category.",
    infoCategoryMessage: "About message category",
    categoryErrorCase: "You must select a category",
    hikingShoes: "Hiking Shoes",
    marketingBodyText:
      "Hi, get ready to go to the mountains with our new shoes! Go anywhere with #SHO",
    checkHere: "Check here",
    utilityBodyText:
      "Hi, your order is being processed and we will send it as soon as possible.",
    seeDetail: "See detail",
    authenticationBodyText:
      "Your verification code is 288955. For security reasons, don't tell anyone this code.",
    autofill: "Autofill",
    marketingExampleDetail:
      "Informing products, services, and other information not related to transaction or verification.",
    marketingExample:
      "Examples: welcome message, bulletin, promo, coupon, catalog, new opening hour info.",
    utilityExampleDetail:
      "Message related to transaction process, order status, and account info updates.",
    utilityExample:
      "Examples: order confirmation, account profile updates, invoice, receipt, appointment reminder.",
    authenticationExampleDetail:
      "Message has unique code for verification or account login.",
    authenticationExample:
      "Examples: verification code (OTP), account recovery.",
    learnMoreTnC:
      "If the message content does not match the selected category, Meta will adjust the message category.",
    okUnderstand: "Ok, got it",

    chatroomLoadingText:
      "Please wait, we are still preparing the chat details...",
    chatroomLoadingError:
      "Unable to load chats. Please retry in a few moments.",
    subscriptionFreeTrialText: "Free Trial",
    subscriptionFreeTrialValid:
      "Try all starter package feature for free until {0}", // 16 Mei 2023
    subscriptionPackageActiveText: "Active package",
    subscriptionPackageExpiredText: "Expired package",
    subscriptionStartButtonText: "Start subscription",
    subscriptionExtendButtonText: "Extend subscription",
    subscriptionUpgradeButtonText: "Upgrade package",
    subscriptionValidText: "until",
    subscriptionBannerSuccessText: "Subscription package active",
    subscriptionPackageActivePackageText: "The {0} subscription is active",
    subscriptionContactUsText: "Contact us",
    subscriptionDialogCancelText: "Cancel",
    subscriptionDialogProceedText: "Yes, switch package",
    subscriptionDialogProceedTextExceedLimit: "Ok, understand",
    subscriptionDialogTitleText: "Switch subscription package",
    subscriptionDialogTitleTextExceedLimit:
      "Switch to a cheaper subscription package?",
    subscriptionDialogDescriptionText:
      "You would not be able to use {0} account that has been connected if you switch package to Starter",
    subscriptionDialogDescriptionTextExceedLimit:
      "If you want to downgrade, you need to remove the number of channels to max. 50 channels only.",
    subscriptionFreeTrialButtonText: "Try free for {0} days",

    addingVariableCTA:
      "Add variable in the message to insert customer information personalized for each recipient.",
    addVariableLearnSeeMore: "See more",
    addVariable: "+ Add Variable",
    titleAddVariable: "Add variable",
    chooseVariable: "Select variable to add",
    selfInputVariable: "You can also create your own variable",
    inputVariable: "Enter variable name",
    variableErrorState: "Please enter variable",

    titleAddSample: "Examples of variable content",
    subTitleAddSample: "Add examples of variable content for verification",
    contentSubTitleAddSample:
      "Create examples on how the variable looks like in a message when sent to the recipients so that Meta can verify your template. Please don't use real customers’ information.",
    fieldAddSamplePlaceHolder:
      "Enter content example, such as 08123456789 for phone number.",
    backButtonSampleDialog: "Back",
    sampleErrorState: "Please fill example for content",
    variableErrorStateSpecialChar:
      "Do not input special characters for variable",
    createTemplateSubmitDisclaimerTitle: "Meta will review your template first",
    createTemplateSubmitDisclaimerContent:
      "You can use the template after getting approval from Meta within 24 hours.",
    createTemplateViberSubmitDisclaimerTitle: "Create new template",
    createTemplateViberSubmitDisclaimerContent:
      "The template you create will be used for broadcast immediately and appear in the template list.",
    createTemplateSubmitDisclaimerUnderstandCheck:
      "I understand. Don't show this again.",

    templateHeaderCategorySubCategory: "Category & Sub Category",
    bannerVariableHeader:
      "New! Send a personalized broadcast using variable in your template",
    bannerVariableContent:
      "You can include personalized information in the message such as customer’s name and order detail for each recipient.",
    learnMore: "Learn more",
    tooltipVariableTemplate: "Template with variable can't be a new message.",
    tooltipTemplateRejected: "Can't use this template as new message.",
    tooltipVariableLabel:
      "Template has personalized info for each recipient, such as name, phone, order ID.",
    chatroomDownloadFileError:
      "Unable to load file. Please retry in a few moments.",

    broadcastDetailEdit: "Edit",
    broadcastPriceInfoBanner:
      "Price charged for this broadcast is {0} chat credits/contact and may change depending on country code.",
    downloadForTemplateContact: "Choose recipients",
    labelRadioStaticTemplateAllContact: "All customers",
    labelRadioStaticTemplateOneByOne: "Add receiver one by one",
    labelRadioStaticTemplateBulk: "Add receivers at once ",
    labelRadioStaticTemplateGroup: "Choose customer groups",
    labelRadioDownloadDynamicTemplateWithoutData:
      "Download and fill the template",
    labelRadioDownloadDynamicTemplateWithData: "From customer’s group",
    labelAutoCompleteDownloadDynamicTemplateNoGroupData:
      "You don't have any customer group yet",
    labelAutoCompleteDownloadDynamicTemplateWithData: "Choose Group Contact",
    labelAutoCompleteDownloadDynamicTemplateWithDataButton: "Choose",
    broadcastDownloadDynamicTemplateWithDataDescriptionNotCompleted:
      "Download the broadcast list data to complete it by clicking the button bellow",
    broadcastDownloadDynamicTemplateWithDataDescriptionCompleted:
      "Check your contact list by downloading the file.",
    broadcastUploadDynamicTemplateWithDataDescriptionNotCompleted:
      "If there’s any data changes, please re-upload your file with <b>.xls/.xlsx</b>. format.",
    broadcastUploadDynamicTemplateWithDataDescriptionCompleted:
      "If there’s any data changes, please re-upload your file with <b>.xls/.xlsx</b>. format.",
    broadcastUploadDynamicTemplateAlertNotCompleted:
      "Broadcast list data is not complete. Please download and complete the data then re-upload the file.",
    broadcastUploadDynamicTemplateAlertCompleted:
      "Broadcast list data is complete and you can send the broadcast message to your customers.",
    broadcastDownloadDynamicTemplateButton: "Download",
    broadcastUploadDynamicTemplateButton: "Upload",
    broadcastUploadDynamicTemplateTooltip:
      "Delete the uploaded file to upload a new one.",
    labelSetGroupBroadcastData: "Manage group contact",
    placeholderAutoCompleteDownloadDynamicTemplateWithData:
      "{0} ({1} contacts)",
    downloadForTemplateContactContent:
      "To send broadcast, <b>download .xslx</b> for recipient's detail. File is adjusted based on the selected template.",
    broadcastDownloadTitle: "1. Download Template",
    broadcastDownloadDescription:
      "Please <b>download and fill the template</b> to send the broadcast. The file format has been adjusted based on the selected template.",
    broadcastUploadTitle: "2. Upload template",
    broadcastUploadDescription:
      "Make sure you have filled all of the data the upload the file with <b>.xls</b>/ <b>.xslx</b> format.",

    broadcastSelectedTemplateEmpty: "Please select a template",
    broadcastDateEmpty: "Please select the date",
    broadcastTimeEmpty: "Please select the time",
    labelVariable: "Variable",
    uploadedFileTitle: "Uploaded file",
    tooltipButtonUploadFile:
      "To upload new file, remove the previous file first.",

    getPaymentMethodFailedError:
      "Failed to get payment method, please refresh the page",

    storeSettingContactTitle: "Store Contact",
    storeSettingContactPhoneTitle: "Mobile number in {0}",
    storeSettingContactPhoneTitleDescription:
      "Number connected to your {0} account",
    storeSettingContactPhoneUpdate:
      "Currently, phone replacement only available via {0}",

    storeSettingContactPhoneUpdateAlt1:
      "To change the number, please send email to {0} or contact our sales team",

    storeSettingContactWABATitle: "WABA number",
    storeSettingContactWABATitleDescription:
      "Mobile number connected to WABA (WhatsApp Business API) account, must be different from number in {0}",
    storeSettingContactWABAUpdate:
      "Should you have issues for connect WABA account, please contact {0}",
    storeSettingContactWABAUpdateAlt1:
      "If you have problems connecting WABA account, please send email to {0} or contact our sales team",
    storeSettingContactWABAButton: "Connect WABA account",
    storeSettingContactWABAPlaceholder: "Not yet connected to WABA account",

    storeSettingContactEmailTitle: "Email",
    storeSettingContactEmailUpdate:
      "Currently, email replacement only available via {0}",
    storeSettingContactEmailUpdateAlt1:
      "To change email address, please send email to {0} or contact our sales team",
    businessFieldToyAndHobbyLocalsDesc: "Toys & Hobbies",
    businessFieldMaterialAndPropertyLocalsDesc: "Tools & Home Improvement",
    businessFieldBeautyAndHealthLocalsDesc: "Health & Beauty",
    businessFieldAnimalLocalsDesc: "Pet Supplies",
    businessFieldClothAndAccessoryLocalsDesc: "Fashion & Accessories",
    businessFieldVirtualProductLocalsDesc: "Virtual Product",
    businessFieldFnBLocalsDesc: "Food & Beverages",
    businessFieldShopAndMarketLocalsDesc:
      "Mom-and-Pop Store/Small Grocery Store/Minimarket",
    businessFieldFurnitureAndDecorationLocalsDesc: "Home Decor & Furniture",
    businessFieldElectronicLocalsDesc: "Electronics & Gadgets",
    businessFieldAutomotiveLocalsDesc: "Automotive",
    businessFieldFarmAndFisheryLocalsDesc: "Agriculture/Fisheries/Livestock",
    businessFieldServiceLocalsDesc: "Services",
    businessFieldOthersLocalsDesc: "Others",
    businessModeRestaurantLocalsName: "Food Service",
    businessModeRestaurantLocalsDescription:
      "Features in {0} will cover the restaurant’s needs, such as product menu, delivery options, QR code for order, etc. ",
    businessModeOnlineShopLocalsName: "Online Store",
    businessModeOnlineShopLocalsDescription:
      "Features in {0} will cover the online store’s needs, such as courier options, sharing link to store or products, etc. ",

    metabaseFilterPeriodToday: "Today",
    metabaseFilterPeriodYesterday: "Yesterday",
    metabaseFilterPeriodPreviousXDay: "Last {0} days",
    metabaseFilterPeriodThisMonth: "This month",
    metabaseFilterPeriodPreviousMonth: "Previous month",
    metabaseFilterChannelAll: "All",

    dashboardCTAButtonReplacementText:
      "** Contact sales team to activate this feature according to the subscription package",
    dashboardChatbotButtonReplacementText:
      "** Contact sales team to customize chatbot according to subscription package",
    chatListMultipleSelectActionText: "Set action to multiple action",
    chatListMultipleSelectActionTooltip: "Total of selected chats",
    resolveChatConfirmationTotalMessage: " ({0} Messages)",
    assignToCustomerServiceSuccessNotif: "Chat successfully assigned to {0}.",
    assignToCustomerServiceFailedNotif: "Pesan failed to assign to {0}.",
    transactionSearchPlaceholder: "Transaction No / Buyer Name",
    transactionDeliveryTypePlaceholder: "Delivery Type",
    botTooltipEmptyContent:
      "Need help with your chat template? Your assistant is ready to help.",
    botTooltipContent: "Want more engaging template? Get help from {0}!",

    botHeader: "Assistant helps you creating message draft",
    botOptionMarketingIdea: "Marketing message",
    botOptionNotificationIdea: "Notification message",
    botOptionAuthenticationIdea: "Authentication message",
    botOptionOtherIdea: "Information message for closed store",
    botTextFieldPlaceHolder: "Please tell Assistant what do you need",
    botButton: "Generate",
    botOptionTransToIndonesiaLanguage: "Translate into English",
    botOptionTransToEnglishLanguage: "Translate into Indonesian",
    botOptionShortenMessage: "Shorten it",
    botOptionAnotherIdea: "Improve it",
    botLoadingText: "Assistant is typing...",
    botUseIdea: "Use it",
    botNotSatified: "Not satisfied with it?",
    botOptionImprovementIdea: "Improve it",
    botOptionDictionIdea: "Use another diction",
    botErrorContent: "We're incredibly sorry. We're doing all we can to help.",
    botErrorContentRefreshButton: "Re-generate",
    botEmptyContent:
      "Write your message and Assistant will help you finding idea",

    templateGuideTitle: "Rules for Writing Template:",
    templateGuideText1:
      "When you offer goods or services for sale, we consider all messages and media related to your goods or services, including any descriptions, prices, fees, taxes and/or any required legal disclosures, to constitute transactions.",
    templateGuideText1Bold: "Transactions must comply with the ",
    templateGuideText1BoldUnderline: "WhatsApp Commerce Policy.",
    templateGuideText2Bold: "Do not request sensitive identifiers from users, ",
    templateGuideText2:
      "for example, do not ask people to share full length individual payment card numbers, financial account numbers, National Identification numbers, or other sensitive identifiers.",
    templateGuideText3: "The content ",
    templateGuideText3Bold: "does not contains ",
    templateDuigeText3Cont:
      "potentially abusive or threatening content, such as threatening a customer with legal action or threatening to publicly shame them.",
    templateGuideText4: "The message template ",
    templateGuideText4Bold: "is not a duplicate ",
    templateGuideText4Cont:
      "of an existing template. If a template is submitted with the same wording in the body and footer of an existing template, the duplicate template will be rejected by Meta / Facebook.",
    templateGuideEditTemplate: "Edit template",
    templateGuideUnderstand: "Ok, got it",
    cancelBulkDownloadAttachmentButton: "Cancel",
    downloadBulkDownloadAttachmentButton: "Download",
    chatroomDeletedMessageContent: "This message has been deleted",
    deleteBulkDeleteAttachmentText:
      "Are you sure want to delete this {0} message?",
    deleteBulkDeleteAttachmentButton: "Delete",
    cancelBulkDeleteAttachmentButton: "Cancel",

    menuCSPerformanceTabReport: "Report",
    reportSearchLabel: "Search report...",
    reportPeriodLabel: "Period",
    reportSearchButton: "Search",
    reportAdditionalParamsInputTitle: "Input Report Parameter",
    reportMetabaseSecretKeyError: "Metabase don't have secret key",
    reportMetabaseGeneralError:
      "Failed get report data. Please try again later.",
    enableNotification: "Enable Notification",
    enableNotificationGuide:
      "Don't want to miss chats from customers? Click the 🔒 icon next to the {0} URL, then in the notification options, click the enable button.",

    mediaInputAddMore: "Add more",

    statusResponded: "Responded",
    statusUnresponded: "Unresponded",
    titleBlankReport: "No report yet",
    subtitleBlankReport:
      "Contact {0} team to add report according to your needs",

    createTemplateFormLanguage: "Language",
    createTemplateFormLangugeSubtext:
      "Select language for your broadcast message",

    variableOptionName: "Name",
    variableOptionPhoneNumber: "Phone no.",
    variableOptionDate: "Date",
    variableOptionTransactionStatus: "Order status",
    variableOptionTransactionNo: "Transaction ID",
    variableOptionAddress: "Address",
    variableOptionTrackingNo: "Tracking no.",
    variableOptionBilling: "Billing amount",
    variableOptionProductName: "Product name",
    variableOptionDiscountCode: "Promo code",
    variableOptionCustomerId: "User ID",
    variableOptionSize: "Size",
    variableOptionTime: "Time",
    variableOptionLink: "Link",

    maxAttachmentCountExceeded: "Maximum Number of Files Reached (20)",
    orderTotalQuantity: "{0} items",
    orderTotalPrice: " {0} (total estimation)",
    orderDetailButton: "See order details",
    orderDetailDialogTitle: "Order Details",
    orderDetailDialogSku: "SKU: {0}",
    orderDetailDialogProduct: "{0} Products",
    orderDetailDialogTotalPrice: "Total Order",
    errorGetOrdersDetails: "Failed get details ({0})",
    productDetailButton: "See product details",
    productDetailDialogTitle: "Product Details",
    productDetailDialogDescription: "Product Description",
    productDetailDialogSku: "SKU",
    productDetailDialogWebsite: "Website",

    coachmarkBackButton: "Back",
    coachmarkNextButton: "Next",
    coachmarkDoneButton: "Done",
    coachmarkOkButton: "Ok",
    coachmarkStartChannel: "Connect your marketplace and social media here.",
    coachmarkConnectChannel: "Let's continue check on customer chats in Chats.",
    coachmarkButtonAddAdminSetting:
      "If admins have registered in {0}, you can add them here.",
    coachmarkListAdminSetting:
      "This is a list of users who have access to {0}.",
    coachmarkChatSettingTourReplySetting:
      "You can set the auto response chat based on your needs.",
    coachmarkChatSettingTourQuickReply:
      "You can create shortcut to respond faster.",
    coachmarkChatSettingTourRemarks:
      "Do you want to mark the chat? This is where you can add notes.",
    coachmarkChatSettingTourCategory:
      "If a chat remains active, it can be categorized here.",
    coachmarkTabFeaturesAccess:
      "Add new role to configure accessible menu to users",
    createTemplateFormButton: "Add Button",
    createTemplateFormButtonSubText:
      "Add buttons that allow customers to respond to messages or take action",
    createTemplateFormButtonQuickReply: "Quick Reply",
    createTemplateFormButtonCallToAction: "Call to Action",
    createTemplateFormButtonTypeNone: "None",
    createTemplateFormButtonAddQuickReplyButton: "Add button",
    createTemplateFormButtonQuickReplyPlaceholder: "Button text",
    createTemplateFormButtonQuickReplyError: "Can't be more than 25 characters",
    createTemplateFormButtonQuickReplyErrorSeeAllButtons: "See all options",
    createTemplateFormButtonQuickReplyDuplicateErrorMessage:
      "Duplicate button text is not allowed",
    createTemplateFormUrlDuplicateErrorMessage: "Duplicate url is not allowed",
    createTemplateFormButtonCallToActionUrlStaticPlaceholder:
      "Example: http://koalaapp.id",
    createTemplateFormButtonCallToActionUrlDynamicPlaceholder:
      "Broadcast file input URL",
    createTemplateFormButtonVisitWebsiteEmptyError: "Url is required",
    createTemplateFormButtonVisitWebsiteFormatrror:
      "Url starts with http:// or https://",
    changeTemplateTypeCodeSuccess: "Template type changed successfully.",
    changeTemplateTypeCodeFailed: "Template type failed to be changed.",
    newMessageSuccessSnackBar: "Successfully sent new message",
    followUpChatSuccessSnackBar: "Successfully sent follow up chat",
    coachmarkChatPageTourChatList:
      "You can choose and respond to the customer's chat here.",
    coachmarkChatPageTourAssignButton:
      "Click here to assign a user to reply to the incoming messages.",
    coachmarkChatPageTourAssignCs:
      "Assign one of these admins who has been registered to {0}.",
    coachmarkConnectLiveChat:
      "Click here to add Live Chat widget to your website.",
    coachmarkConnectChannelWithLiveChat:
      "Connect your marketplace, web Live Chat, and social media here",
    coachmarkStartChannelWithEmail:
      "Connect your email, marketplace, and social media account here",
    coachmarkStartChannelWithLiveChatAndEmail:
      "Connect your email, marketplace, social media, and web Live Chat account here",

    menuCSPerformanceBroadcastReportTab: "Broadcast Report",
    columnNameTotalBroadcastHistory: "Total Broadcast",
    columnNameTotalSentBroadcastHistory: "Delivered",
    columnNameTotalReadBroadcastHistory: "Read",
    columnNameTotalFailedBroadcastHistory: "Failed",

    addViberBotAll: "All subscribers connected to your Viber bot",
    addViberBotOneByOne: "Add subscriber one by one",
    addViberBotGroup: "Choose subscriber group",

    chatroomMultipleContact: "{0} and {1} other contacts",
    chatroomMultipleContactButton: "View contacts",
    chatroomMultipleContactDialogTitle: "Contact Details",

    chatroomLoadMedia: "Loading media...",
    menuAccessForbiddenText: "You have no permission to access this menu.",

    unConnectedViberChannelText: "Viber Channel is not connected, connect now",
    unConnectedViberChannelButtonText: "Connect now",

    liveChatBannerDashboardTitle: "Introducing Live Chat",
    liveChatBannerDashboardSubtitle:
      "Communicate easily with your customer with your own web Live Chat.",
    liveChatBannerDashboardButton: "Try now",
    liveChatBannerChannelTitle: "Simplify your communication with Live Chat",
    liveChatBannerChannelSubtitle:
      "Install Live Chat widget on your website to experience a hassle free communication with your customer.",
    settingStoreStatusLiveChatVerification:
      "Status will changes after you put code to your website",
    liveChatCreate: "Live Chat",
    liveChatWidgetBasicNameLabel: "Widget name",
    liveChatWidgetBasicDomainLabel: "Domain",
    liveChatWidgetBasicNamePlaceholder: "Eg. Chat CS",
    liveChatWidgetBasicDomainPlaceholder:
      "Eg. https://websiteku.com or http://websiteku.com",
    liveChatWidgetConnectButton: "Connect",
    liveChatWidgetDomainNotFound: "Cannot find domain, please use another one.",
    liveChatWidgetDomainInvalid: "Domain must begin with http:// or https://",
    liveChatWidgetDomainNotFoundNotifError: "Domain not found.",
    liveChatWidgetDomainDuplicated:
      "You have used this domain, please use another one.",
    liveChatWidgetCreateSuccess:
      "Domain is successfully added, please set your widget.",
    liveChatWidgetBasicSettingsTitle: "Basic settings",
    liveChatWidgetBasicSettingsSubtitle:
      "Set name and website domain for your Live Chat widget.",
    liveChatWidgetInstallationSettingsTitle: "Install Live Chat",
    liveChatWidgetInstallationSettingsSubtitle:
      "You need to connect the widget and embed the code bellow to install the Live Chat on to your website.",
    liveChatWidgetInstallationToggleTitle: "Connect widget",
    liveChatWidgetInstallationToggleDesc:
      "Connect Live Chat widget to your website",
    liveChatWidgetInstallationCodeTitle: "Embed widget code",
    liveChatWidgetInstallationCodeDesc:
      "You need to connect the widget and embed the code bellow to install the Live Chat on to your website.",
    liveChatWidgetDisplaySettingsTitle: "Widget appearance",
    liveChatWidgetDisplaySettingsSubtitle:
      "Set your widget appearance according to your liking",
    liveChatWidgetContentSettingsTitle: "Widget content",
    liveChatWidgetContentSettingsSubtitle: "Set your Live Chat content here.",
    liveChatWidgetContentAdminLabel: "Chat persona name",
    liveChatWidgetContentBanner:
      "Your Live Chat will be shown as online or offline according to your store {0}.",
    liveChatWidgetContentBannerOperationalHours: " operational hours",
    liveChatWidgetContentStatusTitle: "Konten widget berdasarkan status",
    liveChatWidgetContentStatusSubtitle:
      "Atur isi konten widget berdasarkan status Live Chat di websitemu",
    liveChatWidgetContentOnlineOption: "Online",
    liveChatWidgetContentOfflineOption: "Offline",
    liveChatWidgetContentTitleLabel: "Title",
    liveChatWidgetContentTitleNote: "Maximum 30 charactres",
    liveChatWidgetContentDescriptionNote: "Maximum 80 charactres",
    liveChatWidgetContentDescriptionLabel: "Message",
    liveChatWidgetDisplayLogoTitle: "Widget logo",
    liveChatWidgetDisplayLogoButton: "Upload logo",
    liveChatWidgetDisplayLogoSubtitle: "Upload your Live Chat widget logo.",
    liveChatWidgetDisplayColorTitle: "Widget colour",
    liveChatWidgetDisplayColorSubtitle:
      "Set your widget colour to match with your brand.",
    liveChatWidgetDisplayLogoErrorSize: "Max. file size 5MB",
    liveChatWidgetDisplayLogoErrorFormat:
      "File format must be .PNG, .JPG, or .JPEG",
    liveChatWidgetDisplayLogoErrorResolution:
      "Max. resolution is 320px x 320px",
    liveChatWidgetDisplayLogoRules: `<ul>
      <li>File format must be .PNG, .JPG, or .JPEG</li>
      <li>Max. resolution is 320px x 320px.</li>
      <li>Max. file size is 5MB</li>
    </ul>`,
    liveChatWidgetDisplayColorButton: "Select color",
    liveChatWidgetInstallConnectTitle: "Connect widget",
    liveChatWidgetInstallConnectSubtitle:
      "Connect Live Chat widget to your website",
    liveChatWidgetInstallConnectToggleOn: "Connected",
    liveChatWidgetInstallConnectToggleOff: "Disconnect",
    liveChatWidgetInstallCodeHighlightTitle: "Embed widget code",
    liveChatWidgetInstallCodeHighlightSubtitle:
      "Copy and paste the code bellow before </body> tag on your website.",
    liveChatWidgetInstallCodeHighlightCopy: "Copy",
    liveChatWidgetInstallCodeHighlightCopySuccess: "Widget successfully copied",
    liveChatWidgetInstallEditButtonCancel: "Batalkan",
    liveChatWidgetInstallEditButtonSave: "Simpan",
    liveChatWidgetSaveErrorEmpty: "This field cannot be empty",
    liveChatWidgetSaveErrorLogoEmpty: "Logo cannot be empty",
    liveChatWidgetSaveErrorEmptySnackbar:
      "Please complete filling the form before saving",
    liveChatWidgetDomainDuplicateNotifError: "This domain has been used.",
    liveChatFailedGetChannelConfig:
      "Get configuration failed. Please Try again.",
    liveChatWidgetSaveConfigSuccess: "Configuration has been saved",
    liveChatWidgetPreviewEmpty:
      "Live Chat widget preview will be displayed here.",
    publishPageTitle: "Publish",
    publishPageSubtitle: "You can post social media content easily and quickly",
    publishCreateTitle: "Create Post",
    publishSelectAccountPlaceholder: "Select account",
    publishCaptionPlaceHolder: "Write caption here..",
    publishErrorMediaType: "Wrong media type",
    publishErrorMediaSize: "Media size can't be larger than {0}MB",
    publishErrorMediaFormat:
      "Wrong {0} format. Please input {0} with format: {1}",
    publishErrorVideoDuration:
      "Video duration is at minimum {0} seconds and maximum {1} seconds",
    publishButton: "Publish",
    publishEmptyAccount: "No social channel connected yet.",
    publishFailedPost: "Failed publish instagram post. {0}",
    publishSuccess: "Successfully posted",

    commentsPageTitle: "Comments",
    commentsPageSubtitle:
      "You can reply all post or reels comments on this page.",
    commentsEmptyPostStateTitle: "There are no comments yet on the post",
    commentsEmptyPostStateSubtitle:
      "All your posts that have comments will be displayed and you can reply here",
    commentsSeeComments: "see comments",
    commentsHideComments: "hide comments",
    commentsSeeReplies: "see replies",
    commentsHideReplies: "hide replies",
    commentsEmptyCommentStateTitle: "No selected comment",
    commentsEmptyCommentStateSubtitle:
      "Click see comments to display comments of selected post",
    commentsWriteRepliesPlaceholder: "write comment...",
    commentsSuccessReply: "Successfully send a comment",
    commentsFailedReply: "Failed to send a comment",
    commentsSelectedPost: `Comments on <span class="text-body-14--bold">@{0}</span> post “{1}“`,
    commentsFailedGetData: "Failed to get data {0} ({1})",

    chatHistoryColumnTagMoreCaption: "+{0} more",
    chatHistoryDialogCsTagTitle: "Tag Details",
    chatHistoryDialogCsTagCustomer: "Customer Name",
    chatHistoryFilterTagDefault: "All tag",
    chatHistorySelectAllTag: "Pilih semua tag",
    chatHistorySearchTag: "Search tag",
    chatHistorySelectedTag: "+{0} more",
    chatHistorySelectedTagMobile: "+{0}",
    chatHistoryResetFilter: "Reset Filter",

    createTemplateErrorWaMeLink:
      "Direct links to WhatsApp aren't allowed for buttons.",

    templateContentInfoHeader: "Information about filling message template",
    templateContentInfoTitleVariableContact: "New, Contact List Variable",
    templateContentInfoContentVariableContact:
      "Personalized your broadcast message. ",
    templateContentInfoAttributeVariableContact: "Check feature.",
    templateContentInfoLinkVariableContact:
      "https://koalaapp.id/blog/430-tips-trik-122546-cara-untuk-menambahkan-data-variabel-kontak-dan-menambahkan-kontak-di-koala",

    templateContentInfoTitleVariable: "Information about variable",
    templateContentInfoContentVariable: "Learn about variable and its usage ",
    templateContentInfoAttributeVariable: "in this page.",

    templateContentInfoTitleContentGuide:
      "How to make your template passes verification",
    templateContentInfoContentContentGuide:
      "Check tips on how your template pass the verification ",
    templateContentInfoAttributeContentGuide: "here.",

    addVariableNewLabel: "NEW!",
    addMappingVariableTitle: "Add Contact List Variable",
    addSelfInputVariableTitle: "Add Variable Manually",
    addMappingVariableHeader: "Contact List Variable",
    addMappingVariableChooseVariablePlaceholder: "Choose Variable",
    addMappingVariableInformationDialogContent:
      "You can add new variable by adding field on contact list.",
    addMappingVariableInformationDialogLink: "Field setting.",
    addVariableOptionMappingVariable: "Contact List Variable",
    addVariableOptionSelfInputVariable: "Add Manual",
    addVariableSelfInputInfoDialogContent:
      "Make your broadcast message more personalized with Contact List Variable.",
    addVariableSelfInputInfoDialogLink: "Check Contact List Variable",
    addVariableButtonText: "Add Variable",

    callCenterContactUs: "Contact Us",
    callCenterIsNotActiveInformationText:
      "Let's improve your customer service! Use Call Center now. Contact us for further information",
    callCenterTitle: "Make a Call",
    callCenterInfoDialog:
      "The calling number will be shuffled and different for each call",
    callCenterInputPhoneNumberTitle: "Enter Phone Number",
    callCenterInputPhoneNumberSubtitle:
      "Enter phone number manually, numeric only.",
    callCenterRecentCallTitle: "Recent Call",
    callCenterRecentCallSubtitle: "List of recent call",
    callCenterFrom: "from",
    callCenterEmptyListContent: "You don't have any call history",
    callCenterEmptyListSubcontent:
      "Let's start calling and connecting with your customers",
    callCenterLeaveSiteCostumeMessage:
      "Are you sure you want to leave? The call will be disconnected.",

    callCenterHistoryTitle: "Call Center History",
    callCenterHistorySubtitle: "You can check the history of making call.",
    downloadHistoryCallDialogTitle: "Download Call History",
    searchContactCallCenterPlaceholder: "Search Contact",
    callHistorySearchAgent: "Select Agent",
    callHistoryPlaceholderFilterStatus: "Select Status",
    callHistoryStatusEstabilished: "Estabilished",
    callHistoryStatusNotConnected: "Not Connected",
    callHistoryColumnCustomerContact: "Customer Contact",
    callHistoryColumnCallStatus: "Call Status",
    callHistoryColumnAgent: "Agent",
    callHistoryColumnTime: "Call Time",
    callHistoryColumnDuration: "Call Duration",
    callHistoryColumAction: "Action",
    callHistoryActionNotConnectedTooltip:
      "Call was not connected. There's no action.",
    callRecordingDialogTitle: "Call Recording",
    callRecordingDialogDownloadCallRecording: "Download call recording",
    callCenterReceiverInvalidErrorMessage: "Phone number is invalid",
    callSessionTrying: "Connecting Call",
    callSessionRinging: "Ringing",
    callSessionConnected: "Call Connected",
    callSessionHold: "Call On Hold",
    callSessionBusy: "Call Busy",
    callSessionNoAnswer: "Call No Answer",
    callSessionRejected: "Call Rejected",
    callSessionEnded: "Call Ended",
    callSessionCancelled: "Call Canceled",
    callSessionFailed: "Call Failed",

    callButtonRedial: "Redial",
    callButtonClose: "Close",
    callButtonMute: "Mute",
    callButtonUnmute: "Unmute",
    callButtonHold: "Hold",
    callButtonUnhold: "Unhold",
    callButtonSetting: "Setting",
    callButtonEndCall: "End Call",
    callButtonAnswer: "Answer Call",
    callButtonReject: "Reject Call",

    callReconnectionTitle: "Failed to Connect PBX Server",
    callReconnectionRetry: "Retry",
    callReconnectionCancel: "Cancel",
    callReconnectionConfirm: "Do you want to reconnect server?",

    callInbound: "Incoming Call",
    customerTitle: "Customer",
    chatroomContactDetailStatusLabel: "Status",
    chatroomContactDetailFormLabel: "Form",
    chatroomContactDetailFormCCLabel: "CCs",
    chatroomContactDetailFormChooseLabel: "Form type",
    chatroomContactDetailFormChooseNoDataLabel: "Form type not available",
    chatroomContactDetailActivityLogLabel: "Activity log",
    chatroomContactDetailFormSaveLabel: "Save",
    required: "Required",

    chatRoomTicketLogDialogTitle: "Activity Log",
    chatRoomTicketLogDialogLoadingText: "Loading Activity Log",

    chatRoomAssignmentDialogTitle: "Assign CS",
    chatRoomAssignmentCsAgentGroupsLoading: "Loading Data...",
    chatRoomAssignmentDialogConfirmation: "Confirm",
    chatRoomAssignmentDialogServingChats: "{0} chats is being served",
    chatRoomAssignmentDialogAssignSuccess: "Chat was successfully assigned",
    chatRoomAssignmentDialogAssignFailed: "Failed assigning chat",

    ticketStatusSelected: "Status ",
    ticketStatusApplyButton: "Apply",
    ticketStatusSucceedSnackbar: "Successfully changed status to {0}.",
    ticketStatusFailedSnackbar: "Change status failed.",
    ticketStatusChatListTooltip: "Status: {0}",
    ticketStatusFilterTitle: "Status",
    respondedStatusFilterTitle: "Displayed chat",

    confirmButton: "Confirm",

    name: "Name",
    email: "Email",
    role: "Role",
    emptyData: "Data not available",
    wabaConversionTitle: "Conversion API",
    wabaConversionAPIDesc:
      "Connect to the Conversion API to create a direct connection between your marketing data and the systems that help optimise ad targeting, decrease cost per result and measure outcomes across Meta technologies.",
    wabaConversionAPISeeMore: "See more",
    wabaConversionAPIConnectButton: "Connect",
    pleaseWait: "We're preparing the data, please wait...",
    remove: "Remove",
    removeAll: "Remove all",
    ticketFormSaveSuccess: "Form info saved",
    ticketFormSaveFailed:
      "Failed to save form info. Make sure have filled properly.",
    formChat: "Form chat",
    invalid: "is invalid",

    chatHistoryFailedFetchDataTitle: "Gagal menampilkan data",
    chatHistoryFailedFetchDataDesc:
      "Ada kendala saat menghubungkan email. Silakan coba lagi",
    chatHistoryFailedFetchDataButton: "Hubungkan ulang",
    chatHistoryOnChatRoomDateFilter: "Date",
    chatHistoryOnChatRoomRemarksFilter: "Remarks",
    chatHistoryOnChatRoomSubjectFilter: "Subject",
    chatHistorySearchLabelFilter: "Search here",
    chatHistoryOnChatRoomFilterLabel: "Search subject chat",
    cancel: "Cancel",

    emailSettingsTitle: "Email Account Settings",
    emailSettingsSubtitle:
      "Set up display name, header, and footer for your email.",
    emailSettingsFailedTitle: "Failed to connect email account",
    emailSettingsFailedSubtitle:
      "An error occurred when trying to connect the email. Please try again.",
    emailSettingsFailedButton: "Reconnect",
    emailSettingsPreviewDisplayName: "Preview display name",
    emailSettingsDisplayName: "Display name",
    emailSettingsEmailAddress: "<alamatemail@kokatto.com>",
    emailSettingsEmailAddressSubtext: "to me, alamat.email",
    emailSettingsDisplayNameLabel: "Display name email sender",
    emailSettingsSuccessConnect: "Successfully connected to {0}.",
    emailSettingsFailedConnect:
      "Failed to add email account. Please try again.",
    emailSettingsFailedUploadImage: "Failed to upload image, please try again.",
    emailSettingsSaveButton: "Save",
    emailSettingsSuccessSave: "Successfully saved settings for {0}.",
    emailSettingsFailedSave:
      "Failed to save settings for {0}. Please try again.",
    emailSettingsHeader: "Header",
    emailSettingsFooter: "Footer",
    coachmarkConnectLiveChatAndEmail:
      "Click here to add Live Chat widget or email account",
    coachmarkConnectEmail:
      "Click here to add email account you want to connect",
    emailSettingsDuplicateConnect:
      "The account is already connected on another platform. Please contact CS.",
    emailSubjectChatroomHeader: "Subject: {0}",

    dataLoadFailed: "Unable to load data. Please retry in a few moments.",

    ticketViewLoading: "Loading Data",
    ticketViewNoDataTitle: "No data available yet",
    ticketViewNoDataSubtitle:
      "If there is any chat from customer to handle, the information will appear here.",
    ticketViewErrorStateTitle: "Failed to Load Data",
    ticketViewErrorStateSubtitle:
      "Something went wrong when retrieving data. Please try again.",
    ticketViewErrorStateButtonText: "Reload",

    labelFilterTypeBroadcastHistory: "Broadcast Type",
    broadcastTypeFilterNormalBroadcast: "Broadcast",
    broadcastTypeFilterFollowUpNewMessage: "Follow Up & New Message",
    broadcastTypeFilterOtp: "OTP",
    columnNameStatusPendingBroadcastHistory: "Pending for approval",
    columnNameStatusCancelledBroadcastHistory: "Cancelled",
    columnNameStatusRejectedBroadcastHistory: "Rejected",
    columnNameStatusExpiredBroadcastHistory: "Expired",

    ticketViewPageShowingText: "Showing {0}-{1} of {2}",
    ticketViewManualTicketCreationButtonText: "Create new chat",

    ticketViewsList: "Views",
    ticketCsHandledByFilter: "Handled by",
    ticketCsNameFilterDisplay: `CS {0} ({1})`,
    ticketResetFilter: "Reset",
    ticketFilterButton: "Filter",
    all: "All",
    tabTitleAdvancedBroadcast: "Advanced Broadcast",
    advancedBroadcastList: "Advanced Broadcast List",
    tabTodaysBroadcastSchedule: "Today's broadcast schedule",
    tabAllBroadcast: "All broadcasts",
    advancedBroadcastButtonCreate: "Add",
    searchBroadcastPlaceholder: "Search broadcast name",
    noBroadcastTodayTitle: "No broadcast schedule for today",
    noBroadcastTodayContent:
      "If there is an advanced broadcast to be sent today, the details will appear here.",
    noBroadcastDataTitle: "No advanced broadcast yet",
    noBroadcastDataContent:
      "All advanced broadcasts you’ve created will appear here.",
    broadcastTableBroadcastName: "Broadcast",
    broadcastTableBroadcastStatus: "Status",
    broadcastTableBroadcastTime: "Broadcast Time",
    broadcastTableBroadcastTotalRecipient: "Total Recipients",
    broadcastTableBroadcastPrice: "Broadcast Cost",
    broadcastTableBroadcastTemplateMessage: "Template Message",
    broadcastTableBroadcastAction: "Action",
    broadcastTableBroadcastLastUpdated: "Last Updated",
    createAdvancedBroadcastTitle: "Advanced Broadcast",
    createBroadcastFormName: "Broadcast name",
    createBroadcastFormNamePlaceholder: "Enter broadcast name",
    createBroadcastFormNameError: "Can't be more than 30 characters",
    createBroadcastFormTemplateVariableIsNotEligible:
      "Template should contain variables of customer contact information",
    createAdvancedBroadcastInfoBannerText:
      "Templates with variables can be used if they only contain info from your contacts.",
    createTemplatePreviewSubtitle:
      "View the broadcast chat you're going to send",
    createAdvancedBroadcastStepRuleTitle:
      "Criteria for broadcast recipients (optional)",
    createAdvancedBroadcastStepRuleSubTitle:
      "Add rules for which customers from your contacts will receive the broadcast",
    createAdvancedBroadcastStepRuleAddRule: "Add rule",
    createAdvancedBroadcastStepRuleSelectVariable: "Select variable",
    createAdvancedBroadcastStepRuleSelectRule: "Select rule",
    createAdvancedBroadcastStepRuleInputDetail: "Input detail",
    createAdvancedBroadcastStepRuleSelectDate: "Select date",
    autoMsgOprIsContains: "Is contain",
    autoMsgOprIsEqual: "Is equal",
    autoMsgOprIsToday: "Is today",
    autoMsgOprIsDateBefore: "Is before",
    autoMsgOprIsDateAfter: "Is after",
    autoMsgOprIsDateBetween: "Is between",
    autoMsgOprIsScheduleDateBefore: "Is before broadcast date",
    autoMsgOprIsScheduleDateAfter: "Is after broadcast date",
    autoMsgOprIsNotEmpty: "Is not empty",
    createAdvancedBroadcastEmptyFieldErrorMessage: "Variable is required",
    createAdvancedBroadcastEmptyRuleErrorMessage: "Rule is required",
    createAdvancedBroadcastEmptyValueErrorMessage: "Detail is required",
    createAdvancedBroadcastDuplicateCriteriaErrorMessage:
      "Variable and rule already exist",
    createAdvancedBroadcastStepScheduleTitle: "Broadcast Schedule",
    createAdvancedBroadcastStepScheduleSubTitle:
      "Set a time to send broadcast to each recipient",
    createAdvancedBroadcastStepScheduleSelectFrequencyPlaceholder:
      "Select delivery frequency",
    createAdvancedBroadcastStepScheduleSelectTime: "Select time",
    createAdvancedBroadcastButtonSend: "Send on schedule",
    createAdvancedBroadcastButtonSaveAsDraft: "Save as draft",
    createAdvancedBroadcastFrequencyListOneTime: "One-time",
    createAdvancedBroadcastFrequencyListInterval: "Interval",
    createAdvancedBroadcastStepScheduleSelectInterval: "Select interval type",
    createAdvancedBroadcastStepScheduleSelectDay: "Select day",
    createAdvancedBroadcastStepScheduleSelectDate: "Select date",
    createAdvancedBroadcastIntervalListDaily: "Daily",
    createAdvancedBroadcastIntervalListWeekly: "Weekly",
    createAdvancedBroadcastIntervalListMonthly: "Monthly",
    createAdvancedBroadcastStepScheduleSelectFrequencyErrorMessage:
      "Frequency is required",
    createAdvancedBroadcastStepScheduleSelectIntervalErrorMessage:
      "Interval type is required",
    createAdvancedBroadcastStepScheduleSelectDayErrorMessage: "Day is required",
    createAdvancedBroadcastStepScheduleSelectDateErrorMessage:
      "Date is required",
    createAdvancedBroadcastStepScheduleInfoBannerText:
      "Broadcast cost may vary depending on the number of contacts per delivery. Cost detail will be available on All Broadcasts menu.",
    createAdvancedBroadcastSendSuccess:
      "Successfully scheduled advanced broadcast to {0} contacts.",
    createAdvancedBroadcastSaveSuccess:
      "Successfully saved broadcast draft. To start delivery, please activate the broadcast.",
    createAdvancedBroadcasDuplicateErrorMessage:
      "The broadcast name already exists. Please choose another name.",
    createAdvancedBroadcastGeneralErrorMessage:
      "Failed to add advanced broadcast. Please try again.",
    advancedBroadcastChatCreditEstimationInfoTitle:
      "Estimated total broadcast cost today: {0} Chat Credits.",
    advancedBroadcastChatCreditEstimationInfoText:
      "All information on this page is updated automatically every day at 00:00.",
    advancedBroadcastStatusNonActive: "Inactive",
    advancedBroadcastStatusWaiting: "Waiting for delivery",
    advancedBroadcastStatusSent: "Delivered",
    advancedBroadcastStatusFailed: "Failed",
    advancedBroadcastStatusChatCreditMinus: "Insufficient balance",
    advancedBroadcastConfirmationUpdateToActiveDialogTitle:
      "Activate broadcast",
    advancedBroadcastConfirmationUpdateToActiveDialogDescription:
      "Broadcast will be activated according to the delivery schedule.",
    advancedBroadcastConfirmationUpdateToNonActiveDialogTitle:
      "Inactivate broadcast",
    advancedBroadcastConfirmationUpdateToNonActiveDialogDescription:
      "Broadcast will be inactivated. The schedule will be canceled.",
    advancedBroadcastConfirmationUpdateToNonActiveDialogYes: "Yes, inactivate",
    advancedBroadcastConfirmationUpdateToActiveDialogYes: "Yes, activate",
    advancedBroadcastConfirmationUpdateDialogCancel: "Cancel",
    advancedBroadcastUpdateToActiveSuccessSnackbar:
      "Activate broadcast successfully. Broadcast will be sent as scheduled.",
    advancedBroadcastUpdateToActiveErrorSnackbar:
      "Failed to activate broadcast. Please try again.",
    advancedBroadcastUpdateToNonActiveSuccessSnackbar:
      "Inactivate broadcast successfully. The schedule will be canceled.",
    advancedBroadcastUpdateToNonActiveErrorSnackbar:
      "Failed to inactivate broadcast. Please try again.",
    advancedBroadcastDownloadFailedFileIconTooltip:
      "Download contacts to manually resend the broadcast",
    advancedBroadcastNameFailedIconTooltip:
      "You can try to resend manually from the Broadcast menu",
    advancedBroadcastNameChatCreditMinusIconTooltip:
      "Please top up your Chat Credits to to send the broadcast",
    advancedBroadcastConfirmationDeleteDialogTitle: "Delete broadcast",
    advancedBroadcastConfirmationDeleteDialogDescription:
      "If there is a schedule connected to this broadcast, the schedule will be deleted.",
    advancedBroadcastConfirmationDeleteDialogYes: "Yes, delete",
    advancedBroadcastSuccessDeleteSnackbar:
      "Successfully deleted advanced broadcast schedule.",
    advancedBroadcastFailedDeleteSnackbar:
      "Failed to delete advanced broadcast schedule. Please try again.",
    advancedBroadcastFailedGetDetail:
      "Failed to get broadcast detail. Please try again.",
    updateAdvancedBroadcastGeneralErrorMessage:
      "Failed to update advanced broadcast. Please try again.",
    createContactConfirmationDialogTitle:
      "Update your changes in advanced broadcast",
    createContactConfirmationDialogDescription:
      "Changes to customer contacts will affect the details of advanced broadcast. To update the details, please update from the advanced broadcast page.",
    createContactConfirmationDialogUnderstand: "Got it",
    advancedBroadcastSyncContactBannerText:
      "There are changes to contacts. Please update to refresh the broadcast detail.",
    advancedBroadcastSyncContactBannerRefreshButton: "Update now",
    advancedBroadcastSuccessSyncContactSnackbar:
      "Successfully updated contacts for broadcasts.",
    advancedBroadcastFailedSyncContactSnackbar:
      "Failed to update contacts. Please try again.",
    advancedFailedGetBroadcastListSnackbar:
      "Failed to get advanced broadcast list. Please try again.",
    updateBroadcastConfirmationDialogDescription:
      "Changes in advanced broadcast will affect the scheduled broadcast. Continue?",
    updateBroadcastConfirmationDialogContinue: "Yes, continue",
    advancedBroadcastTotalEligibleContactText:
      "{0} contacts meet criteria to receive the broadcast",
    advancedBroadcastViewEligibleContactBtntext: "View Data",
    advancedBroadcastViewEligibleContactDialogTitle: "Broadcast Receiver Data",
    advancedBroadcastNoneEligibleContactText:
      "No contacts meet the criteria, please check your rules again",
    advancedBroadcastTotalEligibleContactErrorText:
      "Failed to get eligible contact",

    noActivityLog: "No Activity Log",
    chatroomUnassignedHasNotExpiryDateInstruction:
      "Assign to online agent to reply.",
    settingAdminSelectRoleSupervisorAndCustomerService: "Supervisor 2",
    settingAdminSelectRoleSupervisorAndCustomerServiceDescription:
      "View, reply to, and be assigned to all chats",
    settingAdminSelectRoleCustomerServicePlayMode: "Customer Service 1",
    settingAdminSelectRoleCustomerServicePlayModeDescription:
      "Only view and reply to chats assigned sequentially by the system (Auto Assign).",

    ticketViewBackCaption: "Back to main Chat page",

    advancedBroadcastFailedDownloadHistorySnackbar:
      "Failed to download advanced broadcast history",

    templateHeaderTemplateType: "Template Type",
    templateTypeCRM: "Variable - Only from contacts",
    templateTypeManual: "Variable - Added manually",
    templateTypeStatic: "Static - No variable",
    sendBroadcastConfirmationDialogTitle: "Continue sending advanced broadcast",
    sendBroadcastConfirmationDialogDescription:
      "Broadcasts will be sent and activated according to the scheduled broadcast.",
    saveBroadcastConfirmationDialogTitle:
      "Continute creating advanced broadcast",
    saveBroadcastConfirmationDialogDescription:
      "Broadcast will be created and inactive. Please activate to send advanced broadcast.",

    chatroomErrorServiceUnavailable:
      "WhatsApp service is temporarily unavailable. Please try again soon.",
    chatroomErrorRateLimitHit:
      "High traffic when sending message to WhatsApp, please try again in a few moments ({0})",
    chatroomErrorConnectionTimeOut:
      "Connection to Meta timed out, please try again later",
    activityLogSendEmailTitle: "Send Email",

    advancedBroadcastExpiredTooltip:
      "The broadcast schedule has expired. It cannot be changed.",
    advancedBroadcastOneTimeTooltip: "One-time broadcast cannot be changed",

    disconnectChannelInfoBannerTitle: "Channel has been disconnected",
    disconnectChannelInfoBannerDescription:
      "You can no longer send any message",
    // keep this on the end to overwrite strings from brand specific strings
    ...brandConstants.CUSTOM_WORDINGS.EN,
  },
};
