import { ActionTree, GetterTree, MutationTree } from "vuex";
import { convertBytesToMegaBytes } from "@/utils/file";
import { RefreshableRequestHelper } from "@/helpers/RefreshableRequestHelper";

import { KokattoInboxServiceClient } from "@/serviceClients/KokattoInboxServiceClient";
import { KokattoGetFileLinkRequest } from "@/serviceClients/requests/KokattoGetFileLinkRequest";
import { KokattoGetFileLinkResponse } from "@/serviceClients/responses/KokattoGetFileLinkResponse";
import { isKokattoErrorResponse } from "@/serviceClients/responses/KokattoErrorResponse";

import {
  State,
  ChatRoomState,
  UploadAttachmentPayload,
  EmailAttachment,
  RemoveUploadedEmailAttachmentPayload,
  SetChatRoomActionPayload,
} from "./types";

const ATTACHMENTS_SIZE_LIMIT_MB = 25;

async function getFileLink(filename: string, file: File) {
  const request: KokattoGetFileLinkRequest = {
    filename: filename,
    contentType: file.type,
  };
  const response =
    await RefreshableRequestHelper.requestKokatto<KokattoGetFileLinkResponse>(
      () => {
        const kokattoInboxServiceClient = new KokattoInboxServiceClient();
        return kokattoInboxServiceClient.getFileLink(request);
      }
    );

  return isKokattoErrorResponse(response) ? null : response;
}

async function uploadFileLink(url: string, file: File) {
  await RefreshableRequestHelper.requestKokatto<any>(() => {
    const kokattoInboxServiceClient = new KokattoInboxServiceClient();
    return kokattoInboxServiceClient.uploadFileLink(url, file);
  });

  return true;
}

async function doUploadFile(file: File) {
  const filename = file.name.replace(/ /g, "_");
  const timestamp = Date.now();
  const lastDot = filename.lastIndexOf(".");
  const ext = filename.substring(lastDot);

  const rawFileName = filename.substring(0, lastDot);
  const fileLink = await getFileLink(timestamp + "_" + rawFileName + ext, file);
  console.log("fileLink", fileLink);

  // if failed getting file link
  if (!fileLink) {
    console.log("Get file link failed", file);
    return null;
  }

  const uploadedFileLink = await uploadFileLink(fileLink.url, file);
  if (!uploadedFileLink) {
    console.log("Upload file link failed");
    return null;
  }
  const link = fileLink.url.split("?")[0];

  return link;
}

export const DEFAULT_STATE: State = {
  uploadEmailAttachment: {
    loading: false,
    data: null,
    error: null,
  },
  emailAttachments: [],
  totalAttachmentSize: 0,
};

const state: State = { ...DEFAULT_STATE };

const getters: GetterTree<State, any> = {
  getUploadEmailAttachmentState: (state: State) => state.uploadEmailAttachment,
  getEmailAttachments: (state: State) => state.emailAttachments,
  getTotalAttachmentsSize: (state: State) => state.totalAttachmentSize,
  totalAttachmentsSizeExceededLimit: (state: State) =>
    state.totalAttachmentSize >= ATTACHMENTS_SIZE_LIMIT_MB,
};

const mutations: MutationTree<State> = {
  beginUploadAttachment(state: State) {
    state.uploadEmailAttachment = {
      ...DEFAULT_STATE.uploadEmailAttachment,
      loading: true,
    };
  },
  successUploadAttachment(state: State, payload: EmailAttachment[]) {
    state.uploadEmailAttachment = {
      ...DEFAULT_STATE.uploadEmailAttachment,
      data: payload,
    };
  },
  errorUploadAttachment(state: State, error: Error | null) {
    state.uploadEmailAttachment = {
      ...DEFAULT_STATE.uploadEmailAttachment,
      error,
    };
  },

  setEmailAttachments(state: State, payload: EmailAttachment[]) {
    const totalAttachmentsSize: number = payload.reduce(
      (result: number, emailAttachment: EmailAttachment) => {
        const size = emailAttachment.size as number;
        return result + size;
      },
      0
    );
    state.emailAttachments = [...payload];
    state.totalAttachmentSize = convertBytesToMegaBytes(totalAttachmentsSize);
  },
  removeEmailAttachment(state: State, itemIndex: number) {
    const filteredResults = state.emailAttachments.filter(
      (_, id) => id !== itemIndex
    );
    const totalAttachmentsSize = filteredResults.reduce(
      (result: number, emailAttachments: EmailAttachment) => {
        const size = emailAttachments.size as number;
        return result + size;
      },
      0
    );
    state.emailAttachments = [...filteredResults];
    state.totalAttachmentSize = convertBytesToMegaBytes(totalAttachmentsSize);
  },
  removeAllEmailAttachments(state: State) {
    state.emailAttachments = [];
    state.totalAttachmentSize = 0;
  },
};

const actions: ActionTree<State, any> = {
  // Upload the selected attachments and populate it into "emailAttachments" state
  async uploadEmailAttachment(
    { commit, getters },
    payload: UploadAttachmentPayload
  ): Promise<void> {
    try {
      commit("beginUploadAttachment");

      const { files } = payload;
      if (files?.length) {
        const emailAttachments: EmailAttachment[] = files.map((file: File) => ({
          loading: true,
          filename: file.name,
          url: "",
          mime: file.type,
          size: file.size,
        }));

        // Display 'uploading' state of the files
        commit("setEmailAttachments", [
          ...getters.getEmailAttachments,
          ...emailAttachments,
        ]);

        // Aggregate and execute the attachment upload requests
        const uploadRequests = files.map((fileToUpload: File) =>
          doUploadFile(fileToUpload)
        );
        const results: PromiseSettledResult<string | null>[] =
          await Promise.allSettled(uploadRequests);

        // For each 'fullfilled' results, populate the "emailAttachments" state
        const uploadedAttachmentsArray = results.reduce(
          (
            bucket: EmailAttachment[],
            currentResult: PromiseSettledResult<string | null>,
            index: number
          ): EmailAttachment[] => {
            console.log("currentResult", currentResult);
            if (currentResult.status === "fulfilled") {
              const resultObj: EmailAttachment = {
                loading: false,
                filename: files[index].name,
                url: currentResult.value as string,
                mime: files[index].type,
                size: files[index].size,
              };
              return [...bucket, resultObj];
            }
            return bucket;
          },
          []
        );
        const attachmentsArray = [
          ...getters.getEmailAttachments,
          ...uploadedAttachmentsArray,
        ];
        const filteredAttachmentsArray = attachmentsArray.filter(
          (emailAttachment) => !emailAttachment.loading
        );

        commit("successUploadAttachment", uploadedAttachmentsArray);
        commit("setEmailAttachments", filteredAttachmentsArray);
      }
    } catch (error) {
      console.error(
        "[ERROR] Something went wrong on uploadAttachment() action",
        error
      );
      commit("errorUploadAttachment", error as Error);
    }
  },

  /**
   * Directly set an array of attachments into the "emailAttachments" state.
   * Typically used to handle the comment-with-attachments macro action
   */
  setEmailAttachments({ commit }, payload: EmailAttachment[]) {
    commit("setEmailAttachments", payload);
  },

  // Remove particular uploaded email attachment
  removeEmailAttachment(
    { commit },
    payload: RemoveUploadedEmailAttachmentPayload
  ) {
    commit("removeEmailAttachment", payload.id);
  },

  // Would be called whenever the user sends the message or chooses another inbox/chatroom
  removeAllEmailAttachments({ commit }) {
    commit("removeAllEmailAttachments");
  },

  setChatRoom({ commit }, payload: SetChatRoomActionPayload) {
    console.log("setChatRoom", payload);
    // this action could be subscribed by any components that needs the broadcasted ChatRoom object
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
